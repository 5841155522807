import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "../../components/UserLogin/QRScan";
import * as url from "../../api/uri_helper";
import "./QRListing";
import ReactPlayer from "react-player";
import Modal from "react-bootstrap/Modal";
import { Breadcrumb } from "antd";
// Import Layout
import Layout from "../Layout";
import Search from "../../UserAssets/images/search1.svg";
import logo from "../../UserAssets/images/authenteklogo.png";
import arrow from "../../UserAssets/images/backbutton.svg";
import device from "../../UserAssets/images/img1.png";
import Image from "../../UserAssets/images/img1.jpg";
import thumbnailImage from "../../UserAssets/images/thumbnail_image.png";
import Image1 from "../../UserAssets/images/img2.png";
import Plus from "../../UserAssets/images/plus.svg";
import Copy from "../../UserAssets/images/copy.svg";
import Menu from "../../UserAssets/images/toggle.svg";
import Close from "../../UserAssets/images/close.png";
import Loader from "../loader";
import BackBtn from "../../UserAssets/images/arrow-left.svg";
import PDF from "../../UserAssets/images/pdf.png";
import UrlImage from "../../UserAssets/images/attach.png";
import noimage from "../../UserAssets/images/noImage.jpg";
import support_img from "../../assets/images/support_page/Support QR.png"
//import css
import "../../UserAssets/css/animate.css";
import "../../UserAssets/css/bootstrap.min.css";
import "../../UserAssets/css/style.css";
import { getQrDataDetails } from "../../Redux/Actions/QrDetails";
import axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    fetchAllParentChildQRId,
    fetchAllQrId,
    fetchAllQrIdByGuideType,
    fetchAllQrCodes,
    fetchAllVirtualQrCodes
} from "../../Redux/Api/fetch.api";
import { QrReader } from "react-qr-reader";
import { setQrCodeIdDataByGuideType } from "../../Redux/Actions/QRCodeIdByGuideType";
import { getQrParentChildData } from "../../Redux/Actions/QRParentChild";
import { getQrCodeData } from '../../Redux/Actions/QR';
import Header from "../UserLayout/Header";
import VirtualQRs from "../UserLogin/VirtualQR";
import Footer from "../UserLayout/Footer";
import QRThumbnail from "../UserLogin/QRThumbnail";
//import ShareIcon from "../../UserAssets/images/shareicon.png";
import ShareIcon from "../../UserAssets/images/share.svg";
import zoom from "../../UserAssets/images/zoom.svg";
import CopyIcon from "../../UserAssets/images/copyicon.svg";
import EmailIcon from "../../UserAssets/images/mailicon.svg";
import CloseIcon from "../../UserAssets/images/closewhite.svg";
import { Tooltip } from 'antd';
import SupportRequest from "../UserLogin/SupportRequest";
import QRCodeGenerator from "../UserLogin/QRCodeGenerator";



const QRParentChild = () => {
    const QRParentChildDetail = useSelector(
        (state) => state.allQRParentChildData.qrDetails
    );
    const allQrData = useSelector((state) => state.allQrCodeData.qrTypes);
    const location = useLocation();
    const data = QRParentChildDetail.childQrDetails;
    const [checkVirtualData, setCheckVirtualData] = useState()
    const parentData = QRParentChildDetail.scanedQRDetails;
    const history = useHistory();
    const [currentId, setCurrentId] = useState(null);
    const [comingqrId, setComingQrId] = useState(null);
    const [currentVirtuaId, setCurrentVirtualId] = useState(null);
    const [detailDesc, setDetailDesc] = useState();
    const [showChildImage, setShowChildImage] = useState();
    const [nextPageData, setNextPageData] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [showmodal, setShowModal] = useState(false);
    const [backbutton, setBackButton] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [childDataId, setChildDataId] = useState();
    const [virtualData, setVirtualData] = useState({});
    const [virtualCode, setVirtualCode] = useState();
    const [checkVirtualCode, setCheckVirtualCode] = useState();
    const [checkQRCode, setCheckQRCode] = useState();
    const [parentDataId, setParentDataId] = useState("parentDataId");
    const [qrId, setQRId] = useState();
    var product_Name = allQrData.filter((item, index) => item.qRcodeId == currentId).map(item => item.product_Name)
    const queryParams = new URLSearchParams(location.search);
    const baseUrl = window.location.origin;
    const [supportModalDisplay, setSupportModalDisplay] = useState(false);

    useEffect(() => {
        getparentchilddata(0);
    }, []);

    const getparentchilddata = (qrcode) => {
      
        setComingQrId(qrcode)
        setLoading(true)
        let comingid = 0;
        if (qrcode) {
            comingid = qrcode;
        }
        else {
            let urlData = location.pathname.split("/");
            if (urlData[1].length < 9) {
                setCurrentVirtualId(urlData[1])
                setCurrentId(urlData[1]);
                comingid = parseInt(urlData[1])
            }
        }
        if (comingid) {
            // setLoading(true)
            getQRDetailsonLoad(comingid);
        }
    }

    const getQRDetailsonLoad = (comingid) => {
        fetchAllParentChildQRId(comingid)
            .then((response) => {
                if (response.data.success) {
                   
                    setLoading(false)
                    dispatch(getQrParentChildData(response.data.data));
                    setCheckVirtualCode(response.data.data.isVirtualCode)
                    setCheckQRCode(response.data.data.checkQRCode)
                    setVirtualData(response.data.data)
                    setVirtualCode(response.data.data.virtualQRCode)
                    setCheckVirtualData(response.data.data.checkVirtualCode)
                   // setCurrentId(urlData[1]);
                    setLoading(false)
                }
                else {
                    setErrorMessage("Qr Code is not valid, please try again.");
                }
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const next = (qrCode) => {
        setBackButton(true)
        qrCode ? history.push({
            pathname: "/" + qrCode, state: {
                parentQrCodeId: currentId
            }
        }) : "";
    };

    const backPage = (qrCode) => {
        history.push({
            pathname: "/QRThumbnail/" + qrCode,
            state: {
                currentId: currentId,
            },
        });
    };

    const childPage = (qrCode) => {
        setBackButton(true)
        debugger;
        history.push({
            pathname: "/QRThumbnail/" + qrCode,
            //state: {
            //    currentId: currentId,
            //},
        });
    };

    const handleClose = () => {
        setModal(false);
        setShowModal(false);
    };

    const handleQRModalShow = (mediaDescOfQR) => {
        setModal(true);
        {
            mediaDescOfQR ? setDetailDesc(mediaDescOfQR) : "";
        }
    };

    const navigateWithQueryParam = (newQueryParam) => {

        const newurl = "/" + `${newQueryParam}`
        history.push(newurl);
        getparentchilddata(newQueryParam);
    };

    const handleQRImageShow = (thumbnailName) => {
        setShowModal(true);
        {
            thumbnailName ? setShowChildImage(thumbnailName) : "";
        }
    };

    const copyToClipboard = () => {
        const baseUrl = window.location.origin;
        const link = `${baseUrl}/${currentId}`;
        // const link = `https://tekahelp.com/${currentId}`;
        navigator.clipboard.writeText(link)
            .then(() => {
                alert('Link copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const handleCloseButton = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.querySelector('.dropdown-menu.share-link').classList.remove('show');
    };


    return (
        <div>
            <Header
                isBackButton={backbutton}
                isCurrentid={currentId}
                setSupportModalDisplay={setSupportModalDisplay}
                isSupportIcon={true}
            ></Header>

            {checkVirtualCode && checkVirtualData ? <VirtualQRs param={virtualData}> </VirtualQRs> :

                checkQRCode && !checkVirtualCode && QRParentChildDetail.scanedQRDetails && QRParentChildDetail.scanedQRDetails.qrCode ? (
                    <div className="position-relative">

                        <section class="center-section inner-pages text-center pb-0">
                            {loading ? <Loader /> : ""}

                            <div className="Support-request-show">
                                <SupportRequest supportModalDisplay={supportModalDisplay} setSupportModalDisplay={setSupportModalDisplay} />
                            </div>
                            <div>
                                <div class="Qr-detail mb-0">
                                    {QRParentChildDetail != null ? (

                                        <div>
                                            <div class="container">
                                                <div className="breadcrumb-container pl-0 " >

                                                    <Breadcrumb className="breadcrumb QrParentChild">
                                                        <Breadcrumb.Item>
                                                            {comingqrId ? <a href={`/${currentId}`}>
                                                                <i class="fa-solid fa-house"></i>
                                                            </a>
                                                                : <Link to={`/${currentId}`}>
                                                                    <i class="fa-solid fa-house"></i>
                                                                </Link>
                                                            }
                                                        </Breadcrumb.Item>
                                                        {comingqrId ? (
                                                            <Breadcrumb.Item>
                                                                <a href={`/${currentId}`}>

                                                                    {currentId}
                                                                </a>
                                                            </Breadcrumb.Item>) : ""

                                                        }
                                                        <Breadcrumb.Item>
                                                            {!backbutton ?
                                                                <Link tp={`/${currentId}`}>

                                                                    {currentId}
                                                                </Link> :
                                                                <Link to={`/${comingqrId}`}>

                                                                    {comingqrId}
                                                                </Link>
                                                            }
                                                        </Breadcrumb.Item>
                                                    </Breadcrumb>


                                                    <div className="dropdown shareDropdown">
                                                        <Tooltip title="Click to Share Link">
                                                            <button className="btn btn-bordered dropdown-toggle share-dropdown"
                                                                // title="Click to Share Link"
                                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                                aria-haspopup="true" aria-expanded="false">
                                                                Share <img src={ShareIcon} className=""></img>
                                                            </button>
                                                        </Tooltip>
                                                        <div className="dropdown-menu share-link" aria-labelledby="dropdownMenuButton"

                                                        >
                                                            <div className="qr_head share-text">
                                                                <h5>Share Link</h5>
                                                                <span className="cls_icn close" onClick={handleCloseButton} data-dismiss="dropdown" aria-label="Close" role="button">
                                                                    <img src={CloseIcon} alt="Close" />
                                                                </span>
                                                            </div>

                                                            <div>
                                                                <div className="listing" onClick={copyToClipboard} style={{ cursor: 'pointer' }}>

                                                                    <img src={CopyIcon}
                                                                    // title="Copy Link"
                                                                    >
                                                                    </img>
                                                                    Copy Link
                                                                </div>
                                                                <div className="listing">
                                                                    <a href={`mailto:?subject=&body=Check out this link: ${baseUrl}/${currentId}`}
                                                                        className="email-link">
                                                                        <img src={EmailIcon}
                                                                            // title="Email Link"
                                                                            alt="Email Link" style={{ marginRight: '13px' }} />
                                                                        Email
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="QRImgBox-outer">
                                                    <div className="QRImgBox">
                                                        <div className="parent-qr">The Parent QR</div>
                                                        <div className="ImgBox">
                                                            {QRParentChildDetail.scanedQRDetails.thumbnailName != null ? (
                                                                <img
                                                                    src={
                                                                        QRParentChildDetail.scanedQRDetails &&
                                                                        QRParentChildDetail.scanedQRDetails.thumbnailName
                                                                    }
                                                                />
                                                            ) : (
                                                                <img
                                                                    class="objectFit"
                                                                    src={noimage}
                                                                //  onClick={() => next(details.qrCode)}
                                                                />
                                                            )}

                                                            <button
                                                                class="QRZoomBtn"
                                                                onClick={() => handleQRImageShow(QRParentChildDetail.scanedQRDetails.thumbnailName)}
                                                            >
                                                                <img src={zoom} alt="Zoom" />
                                                            </button>
                                                        </div>
                                                        <div className="QRtext">
                                                            <div className="d-flex gap-2 justify-content-between">
                                                                <div className="label-div">
                                                                    <h4>
                                                                        {QRParentChildDetail.scanedQRDetails &&
                                                                            QRParentChildDetail.scanedQRDetails.product_Name}
                                                                    </h4>
                                                                    <div className="d-flex justify-content-start">
                                                                        <button
                                                                            class="view-more-btn btn btn-primary"
                                                                            id={parentDataId}
                                                                            onClick={() => {
                                                                                !backbutton ?
                                                                                    backPage(
                                                                                        currentId
                                                                                    ) :
                                                                                    backPage(
                                                                                        comingqrId
                                                                                    )
                                                                            }
                                                                            }
                                                                        >
                                                                            View More
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="qr-container gap-2 align-items-center justify-content-end d-flex flex-column">
                                                                    < QRCodeGenerator qrcodeid={currentId} />
                                                                    <div className="d-flex gap-3">
                                                                        <div className="w-100 d-flex flex-column justify-content-start align-items-center">
                                                                            <div className="qr-main_code-outer">
                                                                                {!backbutton ?
                                                                                    <div className="qr-main_code-inner-new"> QR - {currentId}

                                                                                        {" "}

                                                                                    </div> :
                                                                                    <div className="qr-main_code-inner-new"> QR - {comingqrId}
                                                                                        {" "}</div>

                                                                                }
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                           
                                                           
                                                            
                                                            
                                                            
                                                                                                      
                                                           

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row mb-2 row-gap-2">
                                                    {data &&
                                                        data.map((details) => (
                                                            <div className="col-lg-4 col-md-4 col-12">
                                                                <div className="relative whList child--card-whlist h-100">
                                                                    <Link className="h-100"
                                                                      //  to={`/${details.qrCode}`}
                                                                       // onClick={() => backPage(details.qrCode)}
                                                                      //  onClick={() => {
                                                                      //  navigateWithQueryParam(details.qrCode);

                                                                      //  }}
                                                                    >
                                                                        {/* <Link to={{ pathname: "/" + details.qrCode, state: { parentQrCodeId: currentId } }}>*/}

                                                                        <div
                                                                            class="lists--box wow fadeInUp m-0 h-100"
                                                                            data-wow-delay="0.3s"
                                                                        >
                                                                            <div className="child-qr">The Child QR</div>
                                                                            <div class="list-img-QR">
                                                                                {details.thumbnailName ? (
                                                                                    <img
                                                                                        class="objectFit"
                                                                                        src={details.thumbnailName}
                                                                                        onClick={() => backPage(details.qrCode)}
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        class="objectFit"
                                                                                            src={noimage}
                                                                                            onClick={() => backPage(details.qrCode)}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                            <div className="d-flex justify-content-between align-items-center">                                                                            {details.mediaDescOfQR || details.qrCode ? (
                                                                                <div class="list-text-QR flex-grow-1">
                                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                                        <strong>QR - {details.qrCode}</strong>
                                                                                

                                                                                    <div className="view-more-box-btn">
                                                                                        <button
                                                                                            class="btn btn-primary view-more-btn "
                                                                                            id={childDataId}
                                                                                            onClick={() => {

                                                                                                backPage(
                                                                                                    details.qrCode
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            View More
                                                                                        </button>
                                                                                        </div>
                                                                                    </div>
                                                                                   
                                                                                    {/*{details.mediaDescOfQR &&*/}
                                                                                    {/*    details.mediaDescOfQR.length >= 10 ? (*/}
                                                                                    {/*    <div>*/}

                                                                                    {/*        <p>*/}
                                                                                    {/*            {details.mediaDescOfQR.substr(0, 60)}{"... "}*/}
                                                                                    {/*            <Link*/}
                                                                                    {/*                onClick={(e) => {*/}
                                                                                    {/*                    e.stopPropagation();*/}
                                                                                    {/*                    handleQRModalShow(*/}
                                                                                    {/*                        details.mediaDescOfQR*/}
                                                                                    {/*                    );*/}
                                                                                    {/*                }}*/}
                                                                                    {/*            >*/}
                                                                                    {/*                Show More*/}
                                                                                    {/*            </Link>*/}
                                                                                    {/*        </p>*/}
                                                                                    {/*    </div>*/}
                                                                                    {/*) :*/}
                                                                                      {/*  (*/}
                                                                                        <p>{details.mediaDescOfQR}</p>
                                                                                   {/* )}*/}


                                                                                </div>
                                                                            ) : (
                                                                                <div className="list-text no-data">
                                                                                    <p>No Description Available</p>
                                                                                </div>
                                                                            )}
                                                                          

                                                                            {/*<div className="view-more-box-btn">*/}
                                                                            {/*    <button*/}
                                                                            {/*        class="btn btn-primary view-more-btn "*/}
                                                                            {/*        id={childDataId}*/}
                                                                            {/*        onClick={() => {*/}

                                                                            {/*            backPage(*/}
                                                                            {/*                details.qrCode*/}
                                                                            {/*            );*/}
                                                                            {/*        }}*/}
                                                                            {/*    >*/}
                                                                            {/*        View More*/}
                                                                            {/*    </button>*/}
                                                                            {/*    </div>*/}
                                                                            </div>
                                                                        </div>
                                                                      
                                                                    </Link>

                                                                    <div
                                                                        class="QR-Zoom-btn wow fadeInUp"
                                                                        data-wow-delay="0.5s"
                                                                    >
                                                                        <button
                                                                            class="QRZoomBtn"
                                                                            //data-bs-toggle="modal"
                                                                            //data-bs-target="#zoom-btn"
                                                                            onClick={() =>
                                                                                handleQRImageShow(details.thumbnailName)
                                                                            }
                                                                        >
                                                                            <img src={zoom} alt="Zoom" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>

                                            </div>

                                        </div>
                                    ) :
                                        (
                                            <p>No Data Found</p>
                                        )}

                                </div>
                            </div>
                        </section>
                        <Modal
                            show={modal}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            dialogClassName="modal-dialog-centered"
                        >
                            <Modal.Header className="pt-3 pb-2" closeButton>
                                <Modal.Title className="h6 pb-1 font-weight-bold">Media Description</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    <div class="list-text">
                                        <p>{detailDesc}</p>
                                    </div>
                                }
                            </Modal.Body>
                        </Modal>

                        <Modal
                            show={showmodal}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            dialogClassName="modal-dialog-zoom"
                        >
                            <Modal.Header className="pt-3 pb-2" closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    <div className="img-section m-0 flex-wrap d-flex align-items-center">
                                        <img className=" w-100" src={showChildImage}></img>
                                    </div>
                                }
                            </Modal.Body>
                        </Modal>

                        <div
                            class="modal fade"
                            id="zoom-btn"
                            tabindex="-1"
                            aria-labelledby="zoom-btn"
                            aria-hidden="true"
                        >
                            <div class="modal-dialog modal-sm modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <button
                                            type="button"
                                            class="btn-closee"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <img src={CloseIcon}></img>
                                        </button>
                                        <div class="QRImg">
                                            <img
                                                src={
                                                    !showChildImage
                                                        ? QRParentChildDetail.scanedQRDetails &&
                                                        QRParentChildDetail.scanedQRDetails.thumbnailName
                                                        : showChildImage
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                ) :
                    < div className="invalid-QR">
                        {loading ? <Loader /> :

                            <div class="container">
                                <a href="/QrScan" className="home-icon">
                                    <i class="fa-solid fa-house"></i>
                                </a>
                                <div className="invalid">
                                    <h4> QR code is not valid! Please try Again.Thanks </h4>
                                </div>
                            </div>
                        }
                    </div>
            }
            <Footer></Footer>
        </div>
    );
};
export default connect()(QRParentChild);
