import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import '../Quill/Quill.css';

// Define custom font sizes using Quill's Parchment API
const Size = Quill.import('formats/size');
Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '24px', '32px', '48px'];
Quill.register(Size, true);

const ReactQuillEditor = ({ value, onChangeValue }) => {
    const [editorValue, setEditorValue] = useState('');


    useEffect(() => {
        // Update the editor value when the `value` prop changes
        setEditorValue(value);
    }, [value]);

    const handleChange = (content) => {
        setEditorValue(content);
        if (onChangeValue) {
            onChangeValue(content); // Send content back to the parent component
        }
    };

    // Define custom fonts using Quill's Parchment API
    const Font = Quill.import('formats/font');
    Font.whitelist = [
        'arial',
       // 'times-new-roman',
       // 'courier-new',
      //  'comic-sans',
        'georgia',
        'impact',
        'verdana',
        'calibri',
        'helvetica',
       // 'trebuchet-ms',
        'garamond',
        'tahoma'
    ]; // Add desired fonts here
    Quill.register(Font, true);

    // Toolbar configuration with custom font sizes
    const modules = {
        toolbar: {
            container: [
                // Custom toolbar with numeric font sizes
                // [{ header: [1, 2, 3, false] }], // Header options
                ['bold', 'italic', 'underline'], // Formatting
                [{ list: 'ordered' }, { list: 'bullet' }], // Lists
                //  [{ script: 'sub' }, { script: 'super' }], // Subscript/Superscript


                [
                    {
                        size: [
                            '10px',
                            '12px',
                            '14px',
                            '16px',
                            '18px',
                            '24px',
                            '32px',
                            '48px',
                        ], // Font sizes
                    },
                ],
                [{ color: [] }, { background: [] }], // Text color and background
                [{ font: Font.whitelist }],
                // [{ align: [] }], // Text alignment
                ['link', 'image'], // Links, images, videos
                //  [{ indent: '-1' }, { indent: '+1' }], // Indentation
                ['clean'], // Remove formatting
            ],
        },
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'bullet',
        'indent',
        'direction',
        'size',
        'color',
        'background',
        'font',
        'align',
        'link',
        'image',
        'video',
    ];

    return (
        <div>
            <ReactQuill
                theme="snow"
                value={editorValue}
                onChange={handleChange} // Call the callback when the value changes
                modules={modules}
                formats={formats}
            // className="custom-editor"
            // placeholder="Compose something amazing..."
            />
        </div>
    );
};

export default ReactQuillEditor;


