import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as url from "../../api/uri_helper";
import PDF from "../../UserAssets/images/pdf-icn.svg";
import downloadIcon from "../../UserAssets/images/download-icn.svg";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { fetchAllQrCodes, fetchAllQrId } from "../../Redux/Api/fetch.api";
import Layout from "../Layout";
import "../../../src/custom.css";
import { Link } from "react-router-dom";
import UploadComponent from "../QR/upload";
import { getAllQRByLocationId } from "../../Redux/Actions/qrByLocationId";
import { setDeviceTypes } from "../../Redux/Actions/DeviceActions";
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import {
    fetchAllQRByLocationId, fetchDeviceProducts, fetchAllLocationByCompanyId, fetchGuideProducts,
    fetchCompanyDetails, fetchMediaData, fetchProducts, fetchAllDataByMediaId,fetchVirtualQRCode, copyAllQRDataById, fetchAllQrCodesWithThumbnail
} from "../../Redux/Api/fetch.api";
import { getAllLocationByCompanyId } from "../../Redux/Actions/LocationByCompany";
import Loader from "../loader";
import { setAreaTypes } from "../../Redux/Actions/AreaActions";
import { setGuideTypes } from "../../Redux/Actions/GuideActions";
import { setMediaTypes } from "../../Redux/Actions/MediaActions";
import ReactPlayer from "react-player";
import '../QrReview/QRReview.css'
//import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import axios from "axios";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
//import CKEditor from '@ckeditor/ckeditor5-react';
//import ClassicEditor from 'ckeditor5-build-classic-base64-upload';
import { Editor } from '@tinymce/tinymce-react';
import ReactQuillEditor from "../Quill/Quill";

    
   


const Update = () => {

    const { toasts } = useToasterStore();
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
    }, [toasts]);
    const allCompanyDataDetails = useSelector((state) => state.allCompanyDetails.company);
    const selectedCompanyLocations = useSelector((state) => state.allLocationByCompany.allLocationByCompanyId);
    const selectedQrByLocation = useSelector((state) => state.allQRByLocationId.allQRByLocation);  
    const allDeviceTypes = useSelector((state) => state.allDeviceType.deviceTypes);
    const allAreaTypes = useSelector((state) => state.allAreaType.areaTypes);
    const allGuideTypes = useSelector((state) => state.allGuideType.guideTypes);
    const allMediaTypes = useSelector((state) => state.allMediaType.mediaTypes);
    

    const [qrCodeDescription, setQrCodeDescription] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [areaDescription, setAreaDescription] = useState();
    const [countArea, setCountArea] = useState(0);
    const [currentDeviceTypeId, setCurrentDeviceTypeId] = useState();
    const [currentAreaTypeId, setCurrentAreaTypeId] = useState();
    const [productData, setProductData] = useState()
    const [countProduct, setCountProduct] = useState(0);
    const [countQr, setCountQr] = useState(0);
    const [countGuide, setCountGuide] = useState(0);
    const [guideDescription, setGuideDescription] = useState();
   
    
    const [currentGuideTypeId, setCurrentGuideTypeId] = useState();
    const [currentCompanyId, setCurrentCompanyId] = useState(0);
    const [currentLocationId, setCurrentLocationId] = useState(0);
    const [countDevice, setCountDevice] = useState(0);
    const [currentMediaTypeId, setCurrentMediaTypeId] = useState();
    const [currentMediaTypeKey, setCurrentMediaTypeKey] = useState();
    const [deviceName, setDeviceName] = useState([]);
    const [deviceDescription, setDeviceDescription] = useState();
    const [areaName, setAreaName] = useState([]);
    const [guideName, setGuideName] = useState([]);
    const [currentQRCodeId, setCurrentQRCodeId] = useState("Select QR");
    const [ifAddingQrCode, setifAddingQrCode] = useState(false);
    const [country, setCountry] = useState();
    const [state, setState] = useState();
    const [city, setCity] = useState();
    const [postalCode, setPostalCode] = useState();
    const [address, setAddress] = useState();
    const [mediaIdToUpdate, setMediaIdToUpdate] = useState();
    let qrCodeToUpdate = {};
    const [getResponse, setResponse] = useState({})
    const [updateSection, setUpdateSection] = useState({ activeAccordianHeader: false, activeAccordianDevice: false, activeAccordianArea: false, activeAccordianGuides: false, activeAccordianUpload: false, activeAccordianQR: false })
    const [uploadMedia, setUploadMedia] = useState({ image: false, pdf: false, video: false, url: false, text: false })
    let mediaKey = '';
  //  const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [textMedia, setTextMedia] = useState("<p></p>")
    const [mediaDesc, setMediaDesc] = useState("");
    const [countMedia, setCountMedia] = useState(0);
    const [imgPresent, setImgPresent] = useState()
    const [priority, setPriority] = useState();
    const [header, setHeader] = useState();
    const [countText, setCountText] = useState(0);
    const [pdfpresent, setPdfPresent] = useState()
    const [mediaFilename, setMediaFilename] = useState("");
    const priorityList = Array.from({ length: 31 }, (_, index) => ({
        name: index.toString(),
        value: index,
    }));
   // const priorityList = [{ name: "0", value: 0 }, { name: "1", value: 1 }, { name: "2", value: 2 }, { name: "3", value: 3 }, { name: "4", value: 4 }, { name: "5", value: 5 }, { name: "6", value: 6 },];
    const [pdfLink, setpdfLink] = useState();
    const [source, setSource] = useState();
    const [showVideo, setShowVideo] = useState();
    const [mediaName, setMediaName] = useState([]);
    const [urlFormatError, setUrlFormatError] = useState(false);
    const [videoMedia, setVideoMedia] = useState("");
    const [videoFileName, setVideoFileName] = useState("");
    const [pdfFileName, setPDFFileName] = useState("");
    const [pdfMedia, setPdfMedia] = useState("");
    const [imgSrcs, setImgSrcs] = useState([]);
    const [videoFormatError, setVideoFormatError] = useState(false);
    const [urlMedia, setUrlMedia] = useState("");
    const [videoSizeError, setVideoSizeError] = useState(false);
    const InputRef = useRef();
    let history = useHistory();
    const [conditionalStyleImage, setConditionalStyleImage] = useState(false)
    const [changeCount, setChangeCount] = useState(0);
    const [mediaFiles, setMediaFiles] = useState([]);
    const TOAST_LIMIT = 1;
    const editorRef = useRef(null); 

    const [error, setError] = useState({
        video: "",
        imagesErr: "",
        urlMediaErr: "",
        pdfMediaErr: "",
        //durationError: "",
        textMediaError: ""
    });
    const [images, setImages] = useState({
        upload: {
            pictures: [],
            maxFileSize: 5242880,
            imgExtension: [".jpg", ".png", ".jpeg"],
            defaultImages: [
            ],
        },
    });
    const [dataErrorShow, setDataErrorShow] = useState({
        companyErr: "",
        locationErr: "",
        qrcodeErr: "",
        deviceErr: "",
        areaErr: "",
        guideErr: "",
        mediaErr: "",
        priorityErr: "",
        subPriorityErr: "",
        headerErr: "",
    });
    

    useEffect(() => {

        //setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
      //  getAllLocationByCompany();
       // getDeviceTypeData();       
       // allQRByLocation();       
      //  getMediaTypeDetails();        
        let urlData = location.pathname.split("/");
        if (urlData[4] && urlData[6] && urlData[4] !== 0 && urlData[6] !== 0) {
            setCurrentQRCodeId(urlData[4]);
            setMediaIdToUpdate(urlData[6]);           
        }
    }, []);

    useEffect(() => {
        setUploadMedia((prev) => ({ ...prev, image: false, video: false, pdf: false, url: false }))
        setUpdateSection((prev) => ({ ...prev, activeAccordianArea: false, activeAccordianDevice: false, activeAccordianGuides: false, activeAccordianHeader: false, activeAccordianQR: false, activeAccordianUpload: false }))
    }, [])

    useEffect(() => {
        if (mediaIdToUpdate > 0) {
            setLoading(true)
            let urlData = location.pathname.split("/");
            fetchAllDataByMediaId(urlData[6]).then(response => {
              
                setResponse(response.data.data)
                qrCodeToUpdate = response.data.data;
                //qrCodeToUpdate = response.data.data.find((q) => q.mediaId == urlData[6]);
                setCurrentQRCodeId(urlData[4]);
                if (qrCodeToUpdate.guideTypeId)
                    setCurrentGuideTypeId(qrCodeToUpdate.guideTypeId);
                setCurrentAreaTypeId(qrCodeToUpdate.areaTypeId);
                setCurrentMediaTypeId(qrCodeToUpdate.mediaTypeId)
                setCurrentMediaTypeKey(qrCodeToUpdate.mediaKey)
                setCurrentDeviceTypeId(qrCodeToUpdate.deviceTypeId);
                setCurrentLocationId(qrCodeToUpdate.locationId);
                if (qrCodeToUpdate && qrCodeToUpdate.companyId)
                    setCurrentCompanyId(qrCodeToUpdate.companyId);
                if (qrCodeToUpdate && qrCodeToUpdate.locationId)
                    setCurrentLocationId(qrCodeToUpdate.locationId);
                if (qrCodeToUpdate && qrCodeToUpdate.address)
                    setAddress(qrCodeToUpdate.address);
                if (qrCodeToUpdate && qrCodeToUpdate.state)
                    setState(qrCodeToUpdate.state);
                if (qrCodeToUpdate && qrCodeToUpdate.city)
                    setCity(qrCodeToUpdate.city);
                if (selectedCompanyLocations && selectedCompanyLocations.length > 0) {
                    setCurrentLocationId(qrCodeToUpdate.locationId);
                    let countryObj = selectedCompanyLocations.find(
                        (c) => c.locationId == qrCodeToUpdate.locationId
                    );
                    if (countryObj) {
                        setCountry(countryObj.location.country);
                        setPostalCode(countryObj.location.postalCode);
                    }
                }

                // media
                if (qrCodeToUpdate) {

                    if (qrCodeToUpdate.mediaKey == "pdf") {
                        setPdfPresent(qrCodeToUpdate.mediaTypeId)
                    }
                    if (qrCodeToUpdate.mediaKey == "img") {
                        setImgPresent(qrCodeToUpdate.mediaTypeId)
                    }
                    if (mediaIdToUpdate) {
                        setCurrentMediaTypeId(qrCodeToUpdate.mediaTypeId);
                        setMediaDesc(qrCodeToUpdate.mediaDescription);
                    }
                    if (qrCodeToUpdate.mediaDescription != null)
                        setCountMedia(qrCodeToUpdate.mediaDescription.length);
                    setMediaFilename(qrCodeToUpdate.filename);
                    let mediaDetails = allMediaTypes && allMediaTypes.find(
                        (x) => x.mediaTypeId == qrCodeToUpdate.mediaTypeId
                    );
                    if (mediaDetails && mediaDetails.name)
                        setMediaName(mediaDetails.name);
                    if (mediaDetails && mediaDetails.key)
                        setCurrentMediaTypeKey(mediaDetails.key);
                    if (qrCodeToUpdate.mediaKey === "img")
                        setImgSrcs([qrCodeToUpdate.filename]);
                    if (qrCodeToUpdate.mediaKey === "vdo")
                        setShowVideo([qrCodeToUpdate.filename]);
                    if (qrCodeToUpdate.mediaKey === "pdf")
                        setpdfLink(qrCodeToUpdate.filename);
                    if (qrCodeToUpdate.mediaKey === "url") {
                        setUrlMedia(qrCodeToUpdate.filename);
                    }
                    if (qrCodeToUpdate.mediaKey === "Text") {
                        // setTextMedia(resp.data.data?.reproduceSteps === null ? '<p></p>' : resp.data.data?.reproduceSteps);
                        setTextMedia(qrCodeToUpdate.mediaDescription);
                    }
                }

                if (priorityList && qrCodeToUpdate.priority && priorityList.length > 0 || qrCodeToUpdate.priority == 0) {
                    setPriority(qrCodeToUpdate.priority);
                }

                if (qrCodeToUpdate.header) {
                    setHeader(qrCodeToUpdate.header == "undefined" ? "" : qrCodeToUpdate.header);
                }
                if (qrCodeToUpdate.priority == "Select Priority") {
                    setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: true }))
                }
                setLoading(false)
            });
        }

    }, [mediaIdToUpdate]);

    //useEffect(() => {
    //    // Update editor content when qrCodeToUpdate changes
    //    if (qrCodeToUpdate.mediaKey === "Text") {
    //        const newText = qrCodeToUpdate.mediaDescription || "<p></p>";
    //        setTextMedia(newText);

    //        // If the editor is already initialized, update its data
    //        if (editorRef.current) {
    //            editorRef.current.setData(newText);
    //        }
    //    }
    //}, [qrCodeToUpdate]);

    useEffect(() => {
        if (currentCompanyId > 0)
            getAllLocationByCompany();
    }, [currentCompanyId]);

    useEffect(() => {
        if (currentQRCodeId > 0)
        getAreaTypeDetails();
    }, [currentQRCodeId]);

    useEffect(() => {

        if (currentCompanyId > 0)
            allQRByLocation();
    }, [currentCompanyId]);

    useEffect(() => {
        if (currentCompanyId > 0)
        getDeviceTypeData();
    }, [currentCompanyId]);

    useEffect(() => {
        if (currentQRCodeId > 0)
        getGuideTypeDetails()
    }, [currentQRCodeId]);

    useEffect(() => {
        if (currentQRCodeId > 0)
            getMediaTypeDetails();
    }, [currentQRCodeId]);


    useEffect(() => {
       
        if (selectedQrByLocation && selectedQrByLocation.length > 0) {
            let qrcodedeDesc = selectedQrByLocation.find(
                (q) => q.qRcodeId == currentQRCodeId
            );
            if (qrcodedeDesc && qrcodedeDesc.description) {
                setQrCodeDescription(qrcodedeDesc.description);
                setCountQr(qrcodedeDesc.description.length);
            }
            if (qrcodedeDesc && qrcodedeDesc.product_Name) {
                setProductData(qrcodedeDesc.product_Name);
                setCountProduct(qrcodedeDesc.product_Name.length);
            }
        }
    }, [selectedQrByLocation]);

    useEffect(() => {
        if (allGuideTypes && allGuideTypes.length > 0) {
            // setCurrentGuideTypeId(qrCodeToUpdate.guideTypeId);
            let guideType = allGuideTypes.find(
                (g) => g.guideTypeId == currentGuideTypeId
            );
            if (guideType && guideType.name)
                setGuideName(guideType.name);
            if (guideType && guideType.description)
                setGuideDescription(guideType.description);
            if (guideType && guideType.description)
                setCountGuide(guideType.description.length);
        }
    })

    useEffect(() => {
        if (allAreaTypes && allAreaTypes.length > 0) {
            //setCurrentAreaTypeId(qrCodeToUpdate.areaTypeId);
            let areaType = allAreaTypes.find(
                (a) => a.areaTypeId == currentAreaTypeId
            );
            if (areaType && areaType.name)
                setAreaName(areaType.name);
            if (areaType && areaType.description)
                setAreaDescription(areaType.description);
            if (areaType && areaType.description)
                setCountArea(areaType.description.length);
        }
    })

    useEffect(() => {
        if (getResponse && currentMediaTypeId) {
            if (allMediaTypes && allMediaTypes.length > 0) {


                let mediaDetails = allMediaTypes.find(
                    (a) => a.mediaTypeId == currentMediaTypeId
                );
                if (mediaDetails && mediaDetails.name)
                    setMediaName(mediaDetails.name);
                if (mediaDetails && mediaDetails.key)
                    setCurrentMediaTypeKey(mediaDetails.key);
                if (getResponse && getResponse.mediaKey === "img")
                    setImgSrcs([getResponse.filename]);
                if (getResponse && getResponse.mediaKey === "vdo")
                    setShowVideo([getResponse.filename]);
                if (getResponse && getResponse.mediaKey === "pdf")
                    setpdfLink(getResponse.filename);
                if (getResponse && getResponse.mediaKey === "url") {
                    setUrlMedia(getResponse.filename);
                }
                if (getResponse && getResponse.mediaKey === "Text") {
                    // setTextMedia(resp.data.data?.reproduceSteps === null ? '<p></p>' : resp.data.data?.reproduceSteps);
                    setTextMedia(getResponse.mediaDescription);
                }

            }

        }
    }, [getResponse])

    useEffect(() => {
       
        if (selectedCompanyLocations && selectedCompanyLocations.length > 0) {         
            let countryObj = selectedCompanyLocations.find(
                (c) => c.locationId == currentLocationId
            );
            if (countryObj) {
                setCountry(countryObj.location.country);
                setPostalCode(countryObj.location.postalCode);
            }
        }
    }, [selectedCompanyLocations])

    useEffect(() => {
        if (allDeviceTypes && allDeviceTypes.length > 0) {           
            let deviceType = allDeviceTypes.find(
                (a) => a.deviceTypeId == currentDeviceTypeId
            );
            if (deviceType && deviceType.name)
                setDeviceName(deviceType.name);
            if (deviceType && deviceType.description)
                setDeviceDescription(deviceType.description);
            if (deviceType && deviceType.description)
                setCountDevice(deviceType.description.length);
        }

    })

    //useEffect(() => {
    //    if (mediaIdToUpdate) {
    //        //Convert HTML to text
    //        const htmlText = mediaDesc ? mediaDesc.trim() : "";
    //        const parser = new DOMParser();
    //        const htmlDoc = parser.parseFromString(htmlText, 'text/html');
    //        const body = htmlDoc.body;
    //        // Convert the HTML content to a Draft.js ContentState object
    //        const blocksFromHtml = htmlToDraft(htmlText);
    //        const { contentBlocks, entityMap } = blocksFromHtml;
    //        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    //        const editorState = EditorState.createWithContent(contentState);           
    //        setEditorState(editorState);
    //    }
    //}, [mediaDesc]);

    const showDataError = (e) => {
        e.preventDefault();

        let errors = { ...dataErrorShow };
        if (videoSizeError && mediaIdToUpdate) {
            setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: true }))
        }       
        //if (header == null) {
        //   // setConditionalStyleHeader(true)
        //    setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: true }))
        //}
        setDataErrorShow(errors);
      
        //if (errors.headerErr) {
        //    errors.headerErr = "Please Enter Header";
        //    return false;
        //}
        if (errors.priorityErr) {
            errors.priorityErr = "Please select Priority & Header";
            return false;
        } else {
            return true;
        }
    };

    const handleEditorChange = (content, editor) => {
       // const data = editor.getData();
        setTextMedia(content);
       // setEditorState(editorState);
        setUrlMedia()
        setVideoMedia()
        setImages()
        setImgSrcs([])
       // setMediaDesc("");       
        setUploadMedia((prev) => ({ ...prev, pdf: false, image: false, video: false, url: false, text: false }))
        setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
        setChangeCount(changeCount + 1);
    }

   

    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || "";
        sliceSize = sliceSize || 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    const getMediaData = () => {
        const formData = new FormData();
        let isMedia = false;
        // convert base64 to fileData // this is for images
        //image
        mediaFiles.forEach((mFile) => {
            var imageUrl = mFile.split(";");

            var contentType = imageUrl[0].split(":")[1];
            var realData = imageUrl[2].split(",")[1];
            // Convert to blob
            var blob = b64toBlob(realData, contentType);
            const _fileData = new File([blob], imageUrl[1].substring(5)); // passing name
            formData.append("MediaFiles", _fileData);
            isMedia = true;
        });

        // for source / vedios
        if (source) {
            const vedioData = new File([new Blob([videoMedia])], videoFileName);
            formData.append("MediaFiles", vedioData);
            isMedia = true;
        }

        // for PDF
        if (pdfMedia) {
            const pdfData = new File([new Blob([pdfMedia])], pdfFileName);
            formData.append("MediaFiles", pdfData);
            isMedia = true;
        }
        // For TEXT
        if (textMedia) {
            isMedia = false;
        }

        if (mediaIdToUpdate) {
            // Update
            formData.append("QRCodeID", currentQRCodeId);
            formData.append("QRDescription", qrCodeDescription);
            if (productData)
                formData.append("Product_Name", productData);
            formData.append("DeviceDescription", deviceDescription);
            formData.append("GuideDescription", guideDescription);
            formData.append("AreaDescription", areaDescription);
            formData.append("PriorityQr", priority);
            formData.append("companyId", currentCompanyId);
            formData.append("DeviceName", deviceName);
            formData.append("AreaName", areaName);
            formData.append("GuideName", guideName);
            formData.append("Header", header);
            /*formData.append("SubPriority", subPriority);*/
            formData.append("IsMedia", isMedia);
            formData.append("MediaTypeId", currentMediaTypeId);
            formData.append("DeviceTypeID", currentDeviceTypeId);
            formData.append("AreaTypeID", currentAreaTypeId);
            formData.append("GuideTypeID", currentGuideTypeId);
            formData.append("LocationID", currentLocationId);
            formData.append("CompanyId", currentCompanyId);
            formData.append("MediaTypeKey", currentMediaTypeKey);
            // formData.append("MediaDescription", mediaDesc);
            //New Changes
            if (mediaKey != "Text") {
                formData.append("MediaDescription", mediaDesc);
            }
            else {
                formData.append("MediaDescription", textMedia.trim());
            }
            //End
            formData.append("MediaData", urlMedia);
            formData.append("MediaFilename", mediaFilename);
            //if (mediaIdToUpdate)
            formData.append("MediaId", mediaIdToUpdate);
        } 
        return formData;
    };

    const handleHeaderOnChange = (e) => {
        setChangeCount(changeCount + 1);
        setHeader(e.target.value);
        setCountText(e.target.value.length);
        let error = { ...dataErrorShow };
        if (priority != undefined && priority != "Select priority" && priority != null) {           
            setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: false }))
        } else {          
            setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: true }))
        }
        setDataErrorShow(error);
    };

    const handlePriorityOnChange = (e) => {
        if (e.target.value == "Select Priorty") {
            setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: true }))
        }
        if (e.target.value != "Select Priorty") {
            setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: false }))
        } 
    }

    const getMediaDetailsUpload = (e) => {
        const MediaChanges = e.target.value
        setCurrentMediaTypeId(e.target.value)
        setMediaDesc("")
        let mediaDetails = allMediaTypes.find(
            (x) => x.mediaTypeId == MediaChanges
        );
        if (mediaDetails && mediaDetails.name) {
            setMediaName(mediaDetails.name);
            setCurrentMediaTypeKey(mediaDetails.key);
            mediaDetails.mediaDescription ? setMediaDesc(mediaDetails.mediaDescription) : "";
        }
        if (e.target.value == "Select Media") {
            setMediaName("Select Media")
            selectMedia("Select Media")
            setMediaDesc("")
        }
        setDataErrorShow("");
    }

    const getAllLocationByCompany = () => {
        fetchAllLocationByCompanyId(currentCompanyId)
            .then((response) => {
                dispatch(getAllLocationByCompanyId(response.data));
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    };

    const getMediaTypeDetails = () => {
        fetchMediaData()
            .then((response) => dispatch(setMediaTypes(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            });
    };

    const getAreaTypeDetails = () => {
        fetchProducts()
            .then((response) => {
                dispatch(setAreaTypes(response.data));
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    };

    const getGuideTypeDetails = () => {
        fetchGuideProducts()
            .then(response => {

                dispatch(setGuideTypes(response.data))
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    };

    const getDeviceTypeData = () => {
        fetchDeviceProducts()
            .then((response) => {
                dispatch(setDeviceTypes(response.data));
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    };

    const allQRByLocation = () => {
        fetchAllQRByLocationId(currentLocationId)
            .then((response) => dispatch(getAllQRByLocationId(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            });
    };

    const handleImageUploadChange = (files) => {
        setUrlMedia()
        setPdfMedia()
        setVideoMedia()
        const { pictures } = images.upload;      
            setCurrentMediaTypeId(1); // for image type as this method is an en event handler for image upload        
        setImages(
            {
                ...images,
                upload: {
                    ...images.upload,
                    pictures: [...pictures, ...files],
                },
            },
        );
        setMediaFiles([...files]);
        setChangeCount(changeCount + 1);
        setError("");
       
        setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
        setUploadMedia((prev) => ({ ...prev, image: false, video: false, url: false, pdf: false }))
        setImgSrcs([]);
    };

    const handleFileChange = (event) => {
        setPdfMedia()
        setUrlMedia()
        setImages()
        setImgSrcs([])
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const url = URL.createObjectURL(file);
            const size = file.size / 1024 / 1024;
            setSource(file.name);
            setShowVideo(url);
            setVideoMedia(file);
            setVideoFileName(file.name);
            if (file.type == "audio/mpeg") {
                setVideoFormatError(true);
              
                setUploadMedia((prev) => ({ ...prev, video: true }))
            }
            else {
                setError("");
                setVideoFormatError(false);              
                setUploadMedia((prev) => ({ ...prev, video: false, pdf: false, image: false, url: false }))
                setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
                //return;
            }

            if (size > 250) {
                setVideoSizeError(true);
                setConditionalStyleVideo(true)
                setUploadMedia((prev) => ({ ...prev, video: true }))
                setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: true }))
            }
            else {
                setError("");
                setVideoSizeError(false)               
                setUploadMedia((prev) => ({ ...prev, video: false, pdf: false, image: false, url: false }))
                setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
                return;
            }
            setChangeCount(changeCount + 1);
        }
        else {
            setSource();
            setShowVideo();
            setVideoMedia();
            setVideoFileName();
            setConditionalStyleVideo(true)
        }
    };

    const handleProductName = (e) => {
        setProductData(e.target.value)
        setCountProduct(e.target.value.length)
        //let error = { ...dataErrorShow };
        if (e.target.value.trim() != "") {
            setUpdateSection((prev) => ({ ...prev, activeAccordianQR: false }))
        } else {
            setUpdateSection((prev) => ({ ...prev, activeAccordianQR: true }))
        }
        //setDataErrorShow(error);
    }

    const handleDescription = (e) => {
        setQrCodeDescription(e.target.value),
            setCountQr(e.target.value.length),
            setChangeCount(changeCount + 1);
        if (e.target.value.trim() != "") {
            setUpdateSection((prev) => ({ ...prev, activeAccordianQR: false }))
        }
        else {
            setUpdateSection((prev) => ({ ...prev, activeAccordianQR: true }))
        }
    }

    const getDeviceDetails = (e) => {
        setCurrentDeviceTypeId(e.target.value);
        setCopyQRCodeId("");
        let deviceDetails = allDeviceTypes.find(
            (x) => x.deviceTypeId == e.target.value
        );
        if (deviceDetails && deviceDetails.name) {
            setDeviceName(deviceDetails.name)
            setDeviceDescription(deviceDetails.description)
            setCountDevice(deviceDetails.description.length)
        }
        if (e.target.value == "Select Device" || e.target.value == 0) {
            setDeviceName("Select Device")
            setDeviceDescription("")
        }
        setDataErrorShow("");
    };

    const urlDataMedia = (e) => {

        setPdfMedia()
        setVideoMedia()
        setImages()
        setImgSrcs([])
        setUrlMedia(e.target.value);
        var res = e.target.value.match(
            "https?: //(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,}"
        );
        if (res == null) {         
            setUploadMedia((prev) => ({ ...prev, url: true }))
        } else {            
            setUploadMedia((prev) => ({ ...prev, video: false, pdf: false, image: false, url: false }))
            setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
        }
        setError("");
    };

    const onPdfSaveData = (e) => {
        setUrlMedia()
        setVideoMedia()
        setImages()
        setImgSrcs([])
        setTextMedia()
        const files = e.target.files[0];
        setPDFFileName(files.name.replace(/ /g, "_"));
        setPdfMedia(files);
        setError("");        
        setUploadMedia((prev) => ({ ...prev, pdf: false, image: false, video: false, url: false }))
        setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
        setChangeCount(changeCount + 1);
    };

    const selectMedia = (params) => {
        mediaKey = params
        //setMediaKey(params); 
        switch (params) {
            case "img":
                return (
                    <div className=" mt-3 qrBtn row">
                        <div className="col-sm-12 text-center">
                            <UploadComponent
                                {...images ? images.upload : ""}
                                handleChange={handleImageUploadChange}
                                setConditionalStyleImage={setConditionalStyleImage}
                                setUploadMedia={setUploadMedia}
                            />
                        </div>
                        <div className="img-section m-0 flex-wrap d-flex align-items-center">
                            {ifAddingQrCode ? imgSrcs.map((imgSrc) => (
                                <div className="img--box position-relative">
                                    <span
                                        className="position-absolute"
                                        onClick={handleRemoveImgClick}
                                    >
                                        X
                                    </span>
                                    <img src={imgSrc} />

                                </div>
                            )) :
                                imgSrcs.map((imgSrc) => (
                                    <div className="img--box position-relative">
                                        <img src={imgSrc} />

                                    </div>))
                            }
                        </div>
                        {updateSection.activeAccordianUpload && imgPresent ? setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
                            : (
                                ""
                            )}
                    </div>
                );

            case "vdo":
                return (
                    <div className="VideoInput ">
                        <input
                            ref={InputRef}
                            className="VideoInput_input"
                            type="file"
                            ReactPlayer
                            onChange={(e) => handleFileChange(e)}
                            accept=".mov,.mp4,.wmv,.avi,.MOV"
                        />

                        {showVideo ?
                            <ReactPlayer
                                url={showVideo}
                                width="500px"
                                height="300px"
                                controls={true}
                                playing={false}
                           
                            />
                            : ""}

                        {updateSection.activeAccordianUpload && showVideo ? setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
                            : ("")}

                        {
                            <small className="text-danger d-inline-block mt-3">
                                {!videoFormatError ? " " : "Invalid Format"}
                            </small>
                        }
                        {
                            < small className="text-danger d-inline-block mt-3">{!videoSizeError ? ' ' : 'Size Cannot Exceed 250 MB'}</small>

                        }
                    </div>
                );

            case "url":
                return (
                    <div>
                        <input
                            name="urlMedia"
                            className="form-control"
                            placeholder=" https://example.com"
                            type="text"
                            maxLength={50}
                            onChange={urlDataMedia}
                            value={urlMedia}
                        />
                        {updateSection.activeAccordianUpload && urlMedia ? setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false })) : (
                            ""
                        )}
                        {
                            <small className="text-danger d-block mt-2">
                                {!urlFormatError ? " " : "Invalid URL"}
                            </small>
                        }
                    </div>
                );

            case "pdf":
                return (
                    <div>
                        <div style={{ marginBottom: "5px" }}>
                            {" "}
                            {!ifAddingQrCode && pdfpresent ? (
                                <a className="pdf-download-btn" target="blank" href={pdfLink}>
                                    <div><img className="thumb--inner" src={PDF}></img>{" "}
                                        <div>DOWNLOAD FILE</div>
                                    </div>
                                    <span>
                                        <img className="thumb--inner" src={downloadIcon}></img>
                                    </span>
                                </a>
                            ) : (
                                ""
                            )}
                        </div>
                        <input type="file" name="pdfMedia" className="form-control col-sm-6" accept=".pdf" onChange={(e) => { onPdfSaveData(e) }} />

                        {updateSection.activeAccordianUpload && pdfpresent ? setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false })) : (
                            ""
                        )}
                    </div>
                );
            case "Text":
                return (
                    <div className="w-100">
                        <div className="editor_update">

                            {/*<Editor*/}
                            {/*    apiKey='odv2pk7wfqibsx45yegp7vbg1rvndnyivk0sy9mo9h3pke1d'*/}

                            {/*    init={{*/}
                            {/*        plugins: [*/}
                            {/*            // Core editing features*/}
                            {/*            'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',                                       */}
                            {/*            'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'ai', 'mentions', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown', 'importword', 'exportword', 'exportpdf'*/}
                            {/*        ],*/}
                            {/*        menubar: false, // Disable the menubar*/}
                            {/*        toolbar: 'numlist bullist | fontfamily fontsize forecolor| bold italic underline | link image media table  | undo redo | emoticons charmap | removeformat',                                   */}
                            {/*       // placeholder: "Type your content here...", // Add placeholder text*/}
                                  
                            {/*        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),*/}
                            {/*    }}*/}
                            {/*    onEditorChange={handleEditorChange}*/}
                            {/*    value={textMedia}*/}
                            {/*  //  initialValue=""*/}
                            {/*/>*/}
                            <ReactQuillEditor value={textMedia} // Pass the value to the editor
                                onChangeValue={handleEditorChange} // Handle value change in the parent
                            />

                        </div>
                    </div>
                );
            default:
                return <div></div>;
        }
    };

    const getAreaDetails = (e) => {
        setCurrentAreaTypeId(e.target.value);
        let areaDetails = allAreaTypes.find((x) => x.areaTypeId == e.target.value);
        if (areaDetails && areaDetails.name) {
            setAreaName(areaDetails.name);
            setAreaDescription(areaDetails.description)
            setCountArea(areaDetails.description.length)
        }
        if (e.target.value == "Select Area" || e.target.value == 0) {
            setAreaName("Select Area")
            setAreaDescription("")
            setUpdateSection((prev) => ({ ...prev, activeAccordianArea: true }))
        }      
    };

    const getGuideDetails = (e) => {
        setCurrentGuideTypeId(e.target.value);
        let guideDetails = allGuideTypes.find(
            (x) => x.guideTypeId == e.target.value
        );
        guideDetails ? setGuideName(guideDetails.name) : setGuideName("");
        guideDetails ? setGuideDescription(guideDetails.description) : setGuideDescription("");
        if (guideDetails && guideDetails.description) {
            setCountGuide(guideDetails.description.length)
        }
        setDataErrorShow("");
    };
    const updateQrEditDetails = async (e) => {

        //setLoading(true);
        let iffieldserror = showDataError(e);
        if (deviceName == null || deviceName.length < 1) {
            setUpdateSection((prev) => ({ ...prev, activeAccordianDevice: true }))
        }
        if (areaName == null || areaName.length < 1) {
            setUpdateSection((prev) => ({ ...prev, activeAccordianArea: true }))
        }
        if (guideName == null || guideName.length < 1) {
            setUpdateSection((prev) => ({ ...prev, activeAccordianGuides: true }))
        }
        if (mediaName == null || mediaName.length < 1) {
            setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: true }))
        }

        if (videoSizeError == true) {
            setUpdateSection((prev) => ({ ...prev, activeAccordianupload: true }))
        }

        if (priority == null || priority == "Select Priority") {
            setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: true }))
        }
        if (iffieldserror === true && !updateSection.activeAccordianArea && !updateSection.activeAccordianUpload && !updateSection.activeAccordianGuides && !updateSection.activeAccordianDevice && !updateSection.activeAccordianHeader && !videoSizeError) {
            setLoading(false);
            const formData = getMediaData();
            setLoading(true);
            const response_ = await axios({
                method: "put",
                url: url.PUT_UPDATEQREDITDETAILS,
                data: formData,
            }).then((response) => {
                setSource();
                setLoading(false);
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });
                setLoading(false);
                history.push("/QrReview");

                setLoading(false);
                setImages({
                    upload: {
                        pictures: [],
                        maxFileSize: 5242880,
                        imgExtension: [".jpg", ".png", ".jpeg"],
                        defaultImages: [],
                    },
                });
                setSource();
                setUrlMedia();
                setPdfMedia();
            });
        }
    };

    return (
        <div>
            <Toaster />
            <Layout title="QR Review">
                <div className="position-relative">
                    {loading ? <Loader /> : ""}
                    <div className="card-box-header d-inline-flex w-100 align-items-center">
                        <h4 className="m-0">
                            <Link className="backBTN" to="/QrReview">
                                <img src={Arrow_leftbrown}></img>
                                Back
                            </Link>
                        </h4>
                    </div>

                    <div class="content-area new-page">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="false"
                                        aria-controls="collapseOne"
                                        style={{ fontSize: 14.5 }}
                                    >
                                        Company and Location
                                    </button>
                                </h2>

                                <div
                                    id="collapseOne"
                                    class="accordion-collapse collapse "
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>
                                                        Select Company{" "}
                                                        <span className="required-fields"> * </span>{" "}
                                                    </label>
                                                    <div class="input-group">
                                                        <select
                                                            class="form-select mb-0  filter-select"
                                                            aria-label=".form-select-lg example"
                                                            value={currentCompanyId}
                                                            disabled
                                                            onChange={(e) => {
                                                                CompanyDetails(e)
                                                            }}
                                                           
                                                        >
                                                            <option selected="">Select Company</option>
                                                            {allCompanyDataDetails &&
                                                                allCompanyDataDetails.map((company) => (
                                                                    <option
                                                                        key={company.companyId}
                                                                        value={company.companyId}
                                                                    >
                                                                        {company.companyName}{" "}
                                                                    </option>
                                                                ))}                                                                                                                            
                                                        </select>
                                                    </div>
                                                    <div>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>
                                                            Select Location{" "}
                                                            <span className="required-fields"> * </span>{" "}
                                                        </label>
                                                        <div class="input-group">
                                                            <select
                                                                class="form-select mb-0  filter-select"
                                                                aria-label=".form-select-lg example"
                                                                value={currentLocationId}
                                                            disabled                                                                                                                         
                                                        >
                                                            <option selected="">Select Location</option>
                                                            {selectedCompanyLocations &&
                                                                selectedCompanyLocations.map((data) => (
                                                                    <option
                                                                        key={data.locationId}
                                                                        value={data.locationId}
                                                                    >
                                                                        {data.location.name}{" "}
                                                                    </option>
                                                                ))}
                                                                                                                                   
                                                            </select>
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                            
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Country</label>
                                                    <div class="input-group">
                                                        <input
                                                            id="text"
                                                            type="text"
                                                            class="form-control"
                                                            name="text"
                                                            placeholder="Enter Country Name"
                                                            value={country}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>State</label>
                                                    <div class="input-group">
                                                        <input
                                                            id="text"
                                                            type="text"
                                                            class="form-control"
                                                            name="text"
                                                            placeholder="Enter State Name"
                                                            value={state}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>City</label>
                                                    <div class="input-group">
                                                        <input
                                                            id="text"
                                                            type="text"
                                                            class="form-control"
                                                            name="text"
                                                            placeholder="Enter City Name"
                                                            value={city}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Postal Code</label>
                                                    <div class="input-group">
                                                        <input
                                                            id="text"
                                                            type="text"
                                                            class="form-control"
                                                            name="text"
                                                            placeholder="Enter City Name"
                                                            value={postalCode}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Address</label>
                                                    <div class="input-group">
                                                        <textarea
                                                            id="text"
                                                            type="text"
                                                            class="form-control"
                                                            name="text"
                                                            placeholder="Enter Address"
                                                            value={address}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button
                                        class={
                                            /* conditionalStyleQR || updateSection.activeAccordianQR ? "accordion-button collapsed errorStyle" :*/
                                            "accordion-button collapsed "}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                        style={{ fontSize: 14.5 }}
                                    >
                                        QR Information

                                    </button>
                                </h2>
                                <div
                                    id="collapseTwo"
                                    class="accordion-collapse collapse "
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <div class="row ">
                                            <div class="col-md-5">

                                                <div class="form-group">
                                                    <label >
                                                        Select QR{" "}
                                                        <span className="required-fields"> * </span>{" "}

                                                    </label>
                                                    <div class="input-group QR_select">
                                                        <select
                                                            class="form-select mb-0 flex-grow-1 filter-select"
                                                            aria-label=".form-select-lg example"

                                                            style={{ width: 550 }}
                                                            value={currentQRCodeId}                                                           
                                                            disabled
                                                            onChange={(e) => {
                                                                setCurrentQRCodeId(e);
                                                                //getQRCodeDetails(e);
                                                            }}


                                                        >
                                                            <option selected="">Select QR</option>
                                                            {selectedQrByLocation &&
                                                                selectedQrByLocation.map((data) => (
                                                                    <option
                                                                        key={data.qRcodeId}
                                                                        value={data.qRcodeId}
                                                                    >
                                                                        {data.qRcodeId}
                                                                    </option>
                                                                ))}

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            {currentQRCodeId != "Select QR" ? <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Product Name</label>
                                                    <input type='text' placeholder="Enter product name" maxLength={100} className="form-control" value={productData} onChange={(e) => handleProductName(e)}></input>
                                                    <span className="d-inline-block w-100 text-right fs12">
                                                        {countProduct}/100
                                                    </span>
                                                </div>
                                            </div> : ""}

                                            {currentQRCodeId != "Select QR" ? <div >
                                                <div class="form-group">
                                                    <label>QR Description</label>
                                                    <div class="input-group">
                                                        <textarea
                                                            placeholder="Description"
                                                            value={qrCodeDescription}
                                                            maxLength={1000}
                                                            onChange={(e) => {
                                                                handleDescription(e)
                                                            }}
                                                        >
                                                            {" "}
                                                        </textarea>
                                                        <span className="w-100 text-right fs12">
                                                            {countQr}/1000
                                                        </span>
                                                    </div>
                                                </div>
                                            </div> : ""}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button
                                        //class="accordion-button collapsed"
                                        class={updateSection.activeAccordianDevice ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                        style={{ fontSize: 14.5 }}
                                    >
                                        Device Detail
                                        {updateSection.activeAccordianDevice ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <div class="row mb-2">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>
                                                        Select Device Type{" "}
                                                        <span className="required-fields"> * </span>{" "}
                                                    </label>
                                                    <div class="input-group">
                                                        <select
                                                            class="form-select mb-0  filter-select"
                                                            aria-label=".form-select-lg example"
                                                            value={currentDeviceTypeId}
                                                            onChange={(e) => {
                                                               // setChangeCount(changeCount + 1);
                                                                getDeviceDetails(e);
                                                                setCurrentDeviceTypeId(e.target.value);
                                                               
                                                                if (e.target.value != "Select Device") {
                                                                    setUpdateSection((prev) => ({ ...prev, activeAccordianDevice: false }))
                                                                    setDeviceName("Select Device")
                                                                    setDeviceDescription("")
                                                                } else {
                                                                    setUpdateSection((prev) => ({ ...prev, activeAccordianDevice: true }))
                                                                }
                                                                
                                                            }}
                                                        >
                                                            <option selected="">Select Device</option>
                                                            {allDeviceTypes &&
                                                                allDeviceTypes.map(
                                                                    (d) =>
                                                                        !d.isDeleted && d.active && (
                                                                            <option
                                                                                key={d.deviceTypeId}
                                                                                value={d.deviceTypeId}
                                                                            >
                                                                                {" "}
                                                                                {d.name}{" "}
                                                                            </option>
                                                                        )
                                                                )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Device Description</label>
                                                    <div class="input-group">
                                                        <textarea
                                                            placeholder="Description"
                                                            value={deviceDescription}
                                                            disabled
                                                            style={{
                                                                backgroundColor: "#e9ecef", // Light grey background                                                               
                                                            }}
                                                            onChange={(e) => {

                                                                setDeviceDescription(e.target.value),
                                                                    setCountDevice(e.target.value.length);
                                                                  //  setChangeCount(changeCount + 1);
                                                            }}
                                                        ></textarea>
                                                        <span className="w-100 text-right fs12">
                                                            {countDevice}/1000
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button
                                        //class="accordion-button collapsed"
                                        class={updateSection.activeAccordianArea ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour"
                                        aria-expanded="false"
                                        aria-controls="collapseFour"
                                        style={{ fontSize: 14.5 }}
                                    >
                                        Area Details
                                        {updateSection.activeAccordianArea ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                                    </button>
                                </h2>
                                <div
                                    id="collapseFour"
                                    class="accordion-collapse collapse "
                                    aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <div class="row mb-2">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>
                                                        Select Area Type{" "}
                                                        <span className="required-fields"> * </span>{" "}
                                                    </label>
                                                    <div class="input-group">
                                                        <select
                                                            class="form-select mb-0  filter-select"
                                                            aria-label=".form-select-lg example"
                                                            value={currentAreaTypeId}
                                                            onChange={(e) => {
                                                               // setChangeCount(changeCount + 1);
                                                                getAreaDetails(e);
                                                                setCurrentAreaTypeId(e.target.value);
                                                                if (e.target.value != "Select Area") {
                                                                    setUpdateSection((prev) => ({ ...prev, activeAccordianArea: false }))
                                                                   
                                                                } else {
                                                                    setUpdateSection((prev) => ({ ...prev, activeAccordianArea: true }))
                                                                }                                                                
                                                            }}
                                                        >
                                                            <option selected="">Select Area</option>
                                                            {allAreaTypes &&
                                                                allAreaTypes.map(
                                                                    (a) =>
                                                                        !a.isDeleted && a.active && (
                                                                            <option
                                                                                key={a.areaTypeId}
                                                                                value={a.areaTypeId}
                                                                            >
                                                                                {" "}
                                                                                {a.name}{" "}
                                                                            </option>
                                                                        )
                                                                )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Area Description</label>
                                                    <div class="input-group">
                                                        <textarea
                                                            placeholder="Description"
                                                            value={areaDescription}
                                                            disabled
                                                            style={{
                                                                backgroundColor: "#e9ecef", // Light grey background                                                               
                                                            }}
                                                            onChange={(e) => {
                                                                setAreaDescription(e.target.value),
                                                                    setCountArea(e.target.value.length);
                                                                   // setChangeCount(changeCount + 1);
                                                            }}
                                                        ></textarea>
                                                        <span className="w-100 text-right fs12">
                                                            {countArea}/1000
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFive">
                                    <button
                                        //class="accordion-button collapsed"
                                        class={updateSection.activeAccordianGuides ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFive"
                                        aria-expanded="false"
                                        aria-controls="collapseFive"
                                        style={{ fontSize: 14.5 }}
                                    >
                                        Guide Details
                                        {updateSection.activeAccordianGuides ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                                    </button>
                                </h2>
                                <div
                                    id="collapseFive"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingFive"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <div class="row mb-2">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>
                                                        Select Guide Type{" "}
                                                        <span className="required-fields"> * </span>{" "}
                                                    </label>
                                                    <div class="input-group">
                                                        <select
                                                            class="form-select mb-0  filter-select"
                                                            aria-label=".form-select-lg example"
                                                            value={currentGuideTypeId}
                                                            onChange={(e) => {
                                                               // setChangeCount(changeCount + 1);
                                                                getGuideDetails(e);
                                                                setCurrentGuideTypeId(e.target.value);
                                                               // let error = { ...dataErrorShow };
                                                                if (e.target.value != "Select Guide") {
                                                                    setUpdateSection((prev) => ({ ...prev, activeAccordianGuides: false }))
                                                                } else {
                                                                    setUpdateSection((prev) => ({ ...prev, activeAccordianGuides: true }))
                                                                }
                                                               // setDataErrorShow(error);
                                                            }}
                                                        >
                                                            <option selected="">Select Guide</option>
                                                            {allGuideTypes &&
                                                                allGuideTypes.map(
                                                                    (g) =>
                                                                        !g.isDeleted && g.active && (
                                                                            <option
                                                                                key={g.guideTypeId}
                                                                                value={g.guideTypeId}
                                                                            >
                                                                                {" "}
                                                                                {g.name}{" "}
                                                                            </option>
                                                                        )
                                                                )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Guide Description</label>
                                                    <div class="input-group">
                                                        <textarea
                                                            placeholder="Description"
                                                            value={guideDescription}
                                                            disabled
                                                            style={{
                                                                backgroundColor: "#e9ecef", // Light grey background                                                               
                                                            }}
                                                            onChange={(e) => {
                                                                setGuideDescription(e.target.value),
                                                                    setCountGuide(e.target.value.length);
                                                                   // setChangeCount(changeCount + 1);
                                                            }}
                                                        ></textarea>
                                                        <span className="w-100 text-right fs12">
                                                            {countGuide}/1000
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingSix">
                                    <button
                                        //class="accordion-button collapsed"
                                        class={updateSection.activeAccordianUpload || uploadMedia.image || uploadMedia.video || uploadMedia.pdf || uploadMedia.url ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseSix"
                                        aria-expanded="true"
                                        aria-controls="collapseSix"
                                        style={{ fontSize: 14.5 }}
                                    >
                                        Upload Media
                                        {updateSection.activeAccordianUpload ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                                    </button>
                                </h2>
                                <div
                                    id="collapseSix"
                                    class="accordion-collapse collapse show"
                                    aria-labelledby="headingSix"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>
                                                        Select Media{" "}
                                                        <span className="required-fields"> * </span>{" "}
                                                    </label>
                                                    <div class="input-group">
                                                        <select
                                                            class="form-select mb-0  filter-select"
                                                            aria-label=".form-select-lg example"
                                                            value={currentMediaTypeId}
                                                            onChange={(e) => {
                                                               
                                                                setCurrentMediaTypeId(e.target.value)
                                                               
                                                                getMediaDetailsUpload(e)
                                                                if (e.target.value != "Select Media") {
                                                                    setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: true }))                                                                   
                                                                }
                                                                else {
                                                                    setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: true }))                                                                   
                                                                }
                                                            }}
                                                        >
                                                            <option selected="">Select Media</option>
                                                            {allMediaTypes &&
                                                                allMediaTypes.map((mediaTypes) => (
                                                                    <option
                                                                        key={mediaTypes.mediaTypeId}
                                                                        value={mediaTypes.mediaTypeId}
                                                                    >
                                                                        {mediaTypes.name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="addMedia">
                                                    {currentMediaTypeKey != null && currentMediaTypeKey != undefined && currentMediaTypeId != "Select Media" ? (
                                                        <div>{selectMedia(currentMediaTypeKey)}</div>
                                                    ) : (" ")}
                                                </div>
                                            </div>

                                            {
                                                mediaKey != "Text" ?
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Media Description</label>
                                                            <div class="input-group">
                                                                <textarea
                                                                    placeholder="Add Description"
                                                                    value={mediaDesc}
                                                                    onChange={(e) => {
                                                                        setMediaDesc(e.target.value);
                                                                        setCountMedia(e.target.value.length);
                                                                        setChangeCount(changeCount + 1);
                                                                    }}
                                                                ></textarea>
                                                                <span className="w-100 text-right fs12">
                                                                    {countMedia}/1000
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    ""
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingSeven">
                                    <button
                                        class={updateSection.activeAccordianHeader ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseSeven"
                                        aria-expanded="false"
                                        aria-controls="collapseSeven"
                                        style={{ fontSize: 14.5 }}
                                    >
                                        Header & Priority
                                        {updateSection.activeAccordianHeader ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                                    </button>
                                </h2>
                                <div
                                    id="collapseSeven"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingSeven"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>
                                                        Select Priorty{" "}
                                                        <span className="required-fields"> * </span>
                                                    </label>
                                                    <div class="input-group">
                                                        <select
                                                            class="form-select mb-0  filter-select"
                                                            aria-label=".form-select-lg example"
                                                            value={priority}
                                                            onChange={(e) => {
                                                                setChangeCount(changeCount + 1);
                                                                setPriority(e.target.value);
                                                                handlePriorityOnChange(e);
                                                                let error = { ...dataErrorShow };                                                                           setDataErrorShow(error);
                                                            }}
                                                        >
                                                            <option selected="">Select Priorty</option>
                                                            {priorityList &&
                                                                priorityList.map((p, index) => (
                                                                    <option key={index} value={p.value}>
                                                                        {p.name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>
                                                        Add Header{" "}
                                                       
                                                    </label>
                                                    <div class="input-group">
                                                        <textarea
                                                            placeholder="Add Header"
                                                            value={header}
                                                            maxLength="200"
                                                            onChange={(e) => {
                                                                handleHeaderOnChange(e);
                                                            }}
                                                        ></textarea>{" "}
                                                        <span className="w-100 text-right">
                                                            {countText}/200
                                                        </span>
                                                    </div>
                                                </div>                                               
                                            </div>
                                        </div>
                                        <div class="row">
                                        </div>
                                    </div>
                                </div>                               
                            </div>

                            <div class="row">
                                <div class="col-sm-12 mt-2">                                    
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            onClick={(e) => updateQrEditDetails(e)}
                                        >
                                            Update
                                        </button>                                    
                                    <Link className="btn btn-bordered ms-2" to="/QrReview">
                                        Cancel
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </div>
            </Layout>
        </div>
    )
}
export default connect()(Update);
