import axios from "axios";
// import * as url from "../../api/uri_helper"
import * as url from "../../api/uri_helper"

export const fetchProducts = async () => {
    const response = await axios
        .get(url.GET_AREATYPES)

    return response;
};

export const fetchAllAreaId = async (areaId) => {
    const response = await axios
        .get(url.GET_AREATYPEBYID + "/" + areaId)
    return response;
};

export const AddQRCodes = async (qrcodeId) => {

    const response = await axios
        .post(url.SAVE_QRCODES + "/" + qrcodeId)
    return response;
};



export const fetchDeviceProducts = async () => {
    const response = await axios
        .get(url.GET_DEVICETYPES)
    return response;
};

export const fetchAllDeviceId = async (deviceId) => {
    const response = await axios
        .get(url.GET_DEVICEBYID + "/" + deviceId)
    return response;
};

export const fetchGuideProducts = async () => {
    const response = await axios
        .get(url.GET_GUIDETYPES)

    return response;
};

export const fetchAllGuideId = async (guideId) => {
    const response = await axios
        .get(url.GET_GUIDEBYID + "/" + guideId)
    return response;
};

export const fetchLocationProducts = async () => {
    const response = await axios
        .get(url.GET_LOCATIONTYPES)

    return response;
};

export const fetchAllLocationId = async (locationId) => {
    const response = await axios
        .get(url.GET_LOCATIONBYID + "/" + locationId)
    return response;
};

export const fetchUserData = async () => {
    const response = await axios
        .get(url.GET_USER)

    return response;
};

export const fetchUserRoleData = async () => {
    const response = await axios
        .get(url.GET_USERROLES)

    return response;
};


export const fetchCompanyDetails = async () => {
    const response = await axios
        .get(url.GET_COMPANYDETAILS)

    return response;
};

export const fetchAllCompanyId = async (companyId) => {
    const response = await axios
        .get(url.GET_COMPANYDETAILSID + "/" + companyId)
    return response;
};

export const fetchMediaData = async () => {
    const response = await axios
        .get(url.GET_MEDIATYPES)

    return response;
};

export const fetchAllMediaId = async (mediaId) => {
    const response = await axios
        .get(url.GET_MEDIATYPESBYID + "/" + mediaId)
    return response;
};
export const fetchEmails = async () => {
    const response = await axios
        .get(url.GET_EMAILVERIFICATION)

    return response;
};

export const fetchAllActiveNotMappedQRcodes = async () => {
    const response = await axios
        .get(url.GET_ALLACTIVENOTMAPPEDQRCODES)

    return response;
};

export const fetchLocationByQRCodeId = async (qrCode) => {
    const response = await axios
        .get(url.GET_LocationByQRCodeId + "/" + qrCode)
    return response;
};

export const fetchCompanyLocationData = async () => {
    const response = await axios
        .get(url.GET_COMPANYLOCATIONDATA)
    return response;
};

export const fetchNotMappedLocationId = async (locationid) => {
    debugger;
    const response = await axios
        .get(url.GET_VERIFYLOCATIONID(locationid))
    return response;
};

export const fetchAllLocationComapnyId = async (locationCompanyId) => {
    const response = await axios
        .get(url.GET_COMPANYLOCATIONBYID + "/" + locationCompanyId)
    return response;
};



export const fetchCompanyLocationMappingData = async () => {
    const response = await axios
        .get(url.GET_CompanyLocationMapping)

    return response;
};

export const fetchAllLocationByCompanyId = async (id) => {
    const response = await axios
        .get(url.GET_AllLocationByCompanyId(id))

    return response;
};

export const fetchAllQRByLocationId = async (id) => {
    const response = await axios
        .get(url.GET_AllQRByLocationId(id))

    return response;
};

export const fetchAllVirtualQRByLocationId = async (id) => {
    const response = await axios
        .get(url.GET_AllVirtualQRByLocationId(id))

    return response;
};

export const fetchAllQrId = async (qrCode) => {
    const response = await axios
        .get(url.GET_AllQRCODEID + "/" + qrCode)
    return response;
};

export const fetchAllDataByMediaId = async (mediaid) => {
    const response = await axios
        .get(url.GET_AllDATABYMEDIAID + "/" + mediaid)
    return response;
};


export const fetchAllQrIdByGuideType = async (qrId) => {
    const response = await axios
        .get(url.GET_AllQRCODEID_ByGuideType(qrId))
    return response;
};

export const fetchQrDetails = async () => {
    const response = await axios
        .post(url.GET_QRDETAILS) //TODO Correct this api url

    return response;
};

export const fetchAllQrCodes = async () => {
    const response = await axios
        .get(url.GET_GetAllQRCodes())

    return response;
};

export const fetchAllVirtualQrCodes = async () => {
    const response = await axios
        .get(url.GET_ALLVIRTUALQRCODES)

    return response;
};
//for favourite section
export const fetchUpdatedQRCode = async (id) => {
    const response = await axios
        .put(url.UPDATE_FavoritesQRCode(id))
    return response;
}



export const fetchAllQrCodesWithThumbnail = async () => {
    const response = await axios
        .get(url.GET_GetAllQRCodesWithThumbnail())

    return response;
};

export const fetchAllUserId = async (userId) => {
    const response = await axios
        .get(url.GET_USERBYID + "/" + userId)
    return response;
};

export const fetchAllLocationByQRId = async (QRId, locationId) => {
    const response = await axios
        .put(url.UPDATE_LOCATIONFORQRCODEID(QRId, locationId))
    return response;
};

export const updateAllParentQRId = async (QRId, parentId) => {
    const response = await axios
        .put(url.UPDATE_ParentQRCodeId(QRId, parentId))
    return response;
};

export const copyAllQRDataById = async (formData) => {
    const response = await axios
        .post(url.LINK_QRDETAILS, formData)
    return response;
}



export const fetchAllParentChildQRId = async (qrId) => {
    const response = await axios
        .get(url.GET_ParentChildData + "/" + qrId)
    return response;
};

export const fetchAllVirtualQRData = async (qrId) => {
    const response = await axios
        .get(url.GET_VIRTUALQRUSERSECTION + "/" + qrId)
    return response;
};


export const fetchDashboardCount = async () => {
    const response = await axios
        .get(url.GET_DASHBOARDCOUNT)
    return response;
};
export const fetchAllThumbnail = async (pageIndex) => {
    const response = await axios
        .get(url.GET_GetAllThumbnail(pageIndex))
    return response;
}

export const fetchVirtualQRCode = async () => {
    const response = await axios
        .get(url.CREATE_VIRTUALQRCODE)
    return response;
}

export const fetchSearchData =async (filename)=>{
    debugger;
    const response = await axios
        .get(url.GET_SearchByThumbnail(filename))
    return response;
}

export const fetchProductLocation = async (pageIndex)=>{
    
    const response = await axios
        .get(url.GET_GetAllProductLocation(pageIndex))
    return response;
}

export const fetchSearchProductName = async (productname)=>{
    const response = await axios
        .get(url.GET_SearchByProduct(productname))
    return response;
}

export const fetchQRdetailsById = async (id)=>{
    
    const response = await axios
        .post(url.GET_QRDETAILS_BY_ID,id)
    return response;
}