import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from "react-redux";
import * as url from "../../api/uri_helper"
import Loader from "../loader";
import Table, { TableProps } from "antd/lib/table";
import { useHistory } from "react-router-dom";
import Layout from '../Layout';
import '../../../src/custom.css'
import AddImage from "../../assets/images/Add.svg";
import Union from "../../assets/images/Union.svg";
import Edit_light from "../../assets/images/Edit_light.svg";
import Trash_light from "../../assets/images/Trash_light.svg";
import { Link } from 'react-router-dom';
import { SetCompanyDetails } from '../../Redux/Actions/CompanyActions';
import { fetchAllLocationByCompanyId, fetchAllQRByLocationId, fetchAllQrCodes, fetchCompanyDetails, fetchMediaData } from '../../Redux/Api/fetch.api';
import { setMediaTypes } from '../../Redux/Actions/MediaActions';
import { getAllLocationByCompanyId } from '../../Redux/Actions/LocationByCompany';
import { getAllQRByLocationId } from '../../Redux/Actions/qrByLocationId';
import axios from 'axios';
import { Modal, Skeleton } from 'antd';
import { getQrDataDetails } from '../../Redux/Actions/QrDetails';
import { Tooltip } from 'antd';
import { getQrCodeData } from '../../Redux/Actions/QR';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';


const TOAST_LIMIT = 1;
const IndexQrDetails = (handleOpen) => {
    require('dotenv').config();
   
  
   
    const allCompanyDetails = useSelector((state) => state.allCompanyDetails.company);
    const allMediaTypes = useSelector((state) => state.allMediaType.mediaTypes);
    const selectedCompanyLocations = useSelector((state) => state.allLocationByCompany.allLocationByCompanyId);
    const selectedQrByLocation = useSelector((state) => state.allQRByLocationId.allQRByLocation);
    const [currentCompanyId, setCurrentCompanyId, getCurrentCompanyId] = useState(0);
    const [currentLocationId, setCurrentLocationId] = useState(0);
    const [currentQRId, setCurrentQRId] = useState(0);
    const [mastererror, setMasterError] = useState(false);
    const [sort, setSort] = useState('');
    const [iswithmasterqr, setisWithmasterQr] = useState(false);
    const [sortLoc, setSortLoc] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [sortMedia, setSortMedia] = useState('');
    const [sortProductName, setSortProductName] = useState()
    const [sortQrcode, setSortQrcode] = useState('');
    const [totalIndex, setCurrentIndex] = useState()
    const [sortGuide, setSortGuide] = useState('');
    const [sortArea, setSortArea] = useState('');
    const [sortQr, setSortQr] = useState('');
    const [currentMediaId, setCurrentMediaId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [table, setTable] = useState([]);
    const [checkedListOfIds, setCheckedListOfIds] = useState([]);
    const history = useHistory();
    const [pageSize, setPageSize] = useState(50);
    const [pageData, setPageData] = useState({});
    const [qRcodeId, setQRCodeId] = useState("");
    const [qRcodeadd, setQRCodeadd] = useState();
    const [mediaadd, setMediaadd] = useState();
    const [qRcodeError, setQRCodeError] = useState(false);
    const [options, setOptions] = useState({
        pagination: { current: 1 }, pageSize: pageSize
    });
    const [total, setTotal] = useState()
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();
    const { toasts } = useToasterStore();
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
    }, [toasts]);

    useEffect(() => {
        //setTimeout(() => {
            setLoading(false)
        //}, 2000)
       
        // Check if the QRcode exits on LocalStorage 
       
        ReadSearchedItem()
     
        getCompanyDetails()
        getMediaData()
      
        setPageData(options)
        getQRDetails() 

    }, [])

    const handleEdit = (qRcodeId, mediaId) => {
        uncheckcheckbox();
        setisWithmasterQr(false);
            history.push('/Update/QR/QrCodeId/' + qRcodeId + '/MediaId/' + mediaId)
        
    }
    const getQRDetails = () => {
        fetchAllQrCodes()
            .then(response => {
                dispatch(getQrCodeData(response.data))
            }
            )
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const getCompanyDetails = () => {

        fetchCompanyDetails()
            .then(response => dispatch(SetCompanyDetails(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const getMediaData = () => {
        fetchMediaData()
            .then(response => dispatch(setMediaTypes(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const allLocationByCompany = () => {
        fetchAllLocationByCompanyId(currentCompanyId)
            .then(response => dispatch(getAllLocationByCompanyId(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const allQRByLocation = () => {
        fetchAllQRByLocationId(currentLocationId)
            .then(response => dispatch(getAllQRByLocationId(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    // Determine the QRCodeId to send
    let qRCodeIdToSend = 0;
    if (qRcodeId && qRcodeId !== '') {
        qRCodeIdToSend = parseInt(qRcodeId);
    }
    const searchQrDetails = async (options, isReset = false) => {
       
        setLoading(true)       
        if (options && isReset) {
            options.pagination = isReset ? 1 : pageData.pagination;
            options.pageSize = pageData.pageSize;
        }

        const response = await axios.post(url.GET_QRDETAILS, {
            //PageIndex: options.pagination.current ,
            PageIndex: isReset ? 1 : (options.pagination ? options.pagination.current : '1'),
            pageSize: isReset ? 50 : (options.pagination.pageSize !== undefined ? options.pagination.pageSize : options.pageSize),
            companyId: isReset ? 0 : currentCompanyId,
            locationId: isReset ? 0 : currentLocationId,
            mediaTypeId: isReset ? 0 : currentMediaId,
            QRcodeId: isReset
                ? 0
                : (currentQRId === 0
                    ? (qRCodeIdToSend !== undefined && qRCodeIdToSend !== null ? qRCodeIdToSend : 0)
                    : (currentQRId !== undefined && currentQRId !== null ? currentQRId : 0)),

        }).then(response => {


            // Save Searched Items 

            dispatch(getQrDataDetails(response.data)),
                setTable(response.data.data)
            setTotal(response.data.count)
            setOptions({
                ...options,
                pagination: {
                   
                    pageSize: options.pagination.pageSize != undefined ? options.pagination.pageSize : process.env.REACT_APP_DEFAULT_PAGINATION,                   
                    current: options.pagination.current,
                    total: response.data.count,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    showSizeChanger: true,
                }
            })
            setLoading(false)
        })
            .catch((err) => {
                console.log("Err: ", err);
            });
        //setTimeout(() => {
        //    setLoading(false)
        //}, 2000)
    };



    // Call on Search
    const SaveSeachedItems = (qRcodeId) => {
       
        // Save QRcodeId into the local storage 
        localStorage.setItem('qrcodeid', qRcodeId)

    }

    // Call on page load 
    const ReadSearchedItem = () => {
        // Get the QRcodeId from the local storage 
        const searchedQRId = window.localStorage.getItem('qrcodeid')
        if (searchedQRId) {
            setQRCodeId(searchedQRId);
            qRCodeIdToSend = searchedQRId;
            searchQrDetails(options)
        }
        else {
            searchQrDetails(options)
        }
    }

    // Call on Reset
    const ClearSearchItem = () => {
        // Clear the QRcodeId from the local storage 
        localStorage.removeItem('qrcodeid')
    }

    useEffect(() => {
        if (currentCompanyId > 0)
        allLocationByCompany()
    }, [currentCompanyId])

    useEffect(() => {
        if (currentCompanyId >0)
        allQRByLocation()
    }, [currentLocationId])

    const handleChange = (pagination, sorter) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
        searchQrDetails({
            ...updatedOptions,
        });
    };
    const assignThumbnail = async (mediaId, qrCodeId) => {
        setLoading(true);
        var formData = new FormData();
        formData.append('QRCodeID', qrCodeId);
        formData.append('MediaId', mediaId);
        const response_ = await axios({
            method: 'post',
            url: url.POST_UpdateThumbnail,
            data: formData
        }).then(response => {
            searchQrDetails(options);
            setLoading(false);
            toast.success(response.data.message, {
                className: "toastSuccess",
            });
        })
    }

    const deleteMediaData = async (mediaId, options) => {
        setLoading(true)
        await axios.delete(url.DELETEMEDIA_QRDATA(mediaId), {
        }).then(response => {
            toast.success(response.data.message, {
                className: "toastSuccess",
            });
            searchQrDetails(options);
            setLoading(false)
        }).catch((err) => {
            console.log("Err: ", err);
        });
        [total]
    }

    const deleteMultipleMediaData = async () => {
       
        setLoading(true)
        var formData = new FormData();
        formData.append('mediaIds', checkedListOfIds.toString());
        await axios.delete(url.DELETEMULTIPLEMEDIA_QRDATA, {
            data: formData
        }).then(response => {
            setLoading(false)
            searchQrDetails(options);
            setShowButton(false);
            toast.success(response.data.message, {
                className: "toastSuccess",
            });
            history.push('/QrReview')
            setCheckedListOfIds("")

        }).catch((err) => {
            console.log("Err: ", err);
            setLoading(false)
        });
    }

    const uncheckcheckbox = () => {
        var items = document.getElementsByName('checkname');
        for (var i = 0; i < items.length; i++) {
            if (items[i].type == 'checkbox')
                items[i].checked = false;
        }
        setMasterError(false)
    }
    const { confirm } = Modal;
    const handleDelete = (mediaId) => {

        confirm({
            title: 'Are you sure you want to delete this record?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteMediaData(mediaId, options)
            },
            onCancel() {

            },
        });
    }
    const handleDeleteMultiple = () => {
        {
            (checkedListOfIds.length > 1) ?
                confirm({
                    title: 'Are you sure you want to delete these multiple records?',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        deleteMultipleMediaData()
                    },
                    onCancel() {
                        uncheckcheckbox()
                        setShowButton(false)
                        setCheckedListOfIds('')
                    },
                })
                :
                confirm({
                    title: 'Are you sure you want to delete this row ?',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        deleteMultipleMediaData()
                    },
                    onCancel() {
                        uncheckcheckbox()
                        setShowButton(false)
                        setCheckedListOfIds('')
                    },
                })
        };
    }

    const handleaddwithcheck = () => {
        
        if (checkedListOfIds.length > 1) {
            toast.error("You cannot apply master on multiple rows", {
                className: "toastSuccess",
            });
           // uncheckcheckbox();
           // setCheckedListOfIds("");
        }        
        else {          
            history.push('/Add/QR/QrCodeId/' + qRcodeadd + '/MediaId/' + mediaadd)           
        }
       
    }

    const handleCheck = (mediaId, qrcodeid) => {
        setisWithmasterQr(true);
        setQRCodeadd(qrcodeid);
        setMediaadd(mediaId)
      
        var updatedList = [...checkedListOfIds];
        if (event.target.checked) {
            updatedList = [...checkedListOfIds, mediaId];
        } else {
            updatedList.splice(checkedListOfIds.indexOf(mediaId), 1);
        }
        setCheckedListOfIds([...updatedList]);
    };

    const handleCompanyChange = (e) => {
        let companyData = allCompanyDetails.filter(x => x.companyId == e.target.value)
        setCurrentCompanyId(e.target.value)
        if (e.target.value == 0) {
            setCurrentLocationId(0);
            setCurrentQRId(0);
        }
    }

    const resetButtonSearch = async () => {
        setCurrentLocationId(0);
        setCurrentMediaId(0);
        setCurrentQRId(0);
        setCurrentCompanyId(0);
        qRCodeIdToSend = 0;
        searchQrDetails(options, true)
    }

    const columns = [
        {
            title: " ",
            dataIndex: "",
            render: (data,mediaId) => (<div className="checkgroup">
                {data.qRcodeId == data.primaryQR ?
                    <input type="checkbox" className="btn-checkbox" name="checkname"
                        onClick={() => { setShowButton(true); }} onChange={() => { handleCheck(data.mediaId, data.qRcodeId) }}
                        key={data.mediaId} {...checkedListOfIds.length < 1 ? setShowButton(false) : ""} {...checkedListOfIds.length <= 1 ? setMasterError(false) : ""}
                    ></input> : ""
                }
            </div>)
        },

        {
            title: "Table Id",
            dataIndex: "mediaId",
            className: "tableID",
            sorter: (a, b) => a.mediaId - b.mediaId,
            sortOrder: sortMedia,
            onHeaderCell: () => ({
                onClick: () => setSortMedia(sortMedia === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            }),
        },
        {
            title: "Product Name",
            dataIndex: "product_Name",
            render: (product_Name) => <Tooltip placement='topLeft' className="media-tooltip prdouct-name" title={product_Name}>{product_Name}</Tooltip>,
            sorter: (a, b) => a.mediaId - b.mediaId,
            sortOrder: sortProductName,
            onHeaderCell: () => ({
                onClick: () => setSortProductName(sortProductName === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            }),
        },

        {
            title: "QR Code",
            dataIndex: "qRcodeId",
            sorter: (a, b) => a.qRcodeId - b.qRcodeId,
            sortOrder: sortQrcode,
            onHeaderCell: () => ({
                onClick: () => setSortQrcode(sortQrcode === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            }),
        },

        {
            title: "Primary QR ",
            dataIndex: "primaryQR",
        },

        {
            title: "Company",
            dataIndex: "companyName",
            className: "companyName",
            render: (companyName) => <Tooltip title={companyName}>{companyName}</Tooltip>,
            sorter: (a, b) => a.companyName.localeCompare(b.companyName),
            sortOrder: sort,
            onHeaderCell: () => ({
                onClick: () => setSort(sort === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            }),
        },
        {
            title: "Location",
            dataIndex: "name",
            className: "Locations",
            render: (Locations) => <Tooltip title={Locations}>{Locations}</Tooltip>,
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortOrder: sortLoc,
            onHeaderCell: () => ({
                onClick: () => setSortLoc(sortLoc === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            }),
        },
        //{
        //    title: "QR Description",
        //    dataIndex: "qR_Description",
        //    className: "QRdescription ellipsss",
        //    render: (qR_Description) => <Tooltip title={qR_Description}>{qR_Description}</Tooltip>,
        //    sorter: (a, b) => a.qR_Description.localeCompare(b.qR_Description),
        //    sortOrder: sortQr,
        //    onHeaderCell: () => ({
        //        onClick: () => setSortQr(sortQr === 'ascend' ? 'descend' : 'ascend'),
        //        sortDirections: ['ascend', 'descend', 'ascend'],
        //    }),
        //},
        {
            title: "Guide Type ",
            dataIndex: "guideType",
            className: "guide-type",
            sorter: (a, b) => a.guideType.localeCompare(b.guideType),
            sortOrder: sortGuide,
            onHeaderCell: () => ({
                onClick: () => setSortGuide(sortGuide === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            }),
        },

        {
            title: "Area Type ",
            dataIndex: "areaType",
            className: "areaType",
            render: (areaType) => <Tooltip title={areaType}>{areaType}</Tooltip>,
            sorter: (a, b) => a.areaType.localeCompare(b.areaType),
            sortOrder: sortArea,
            onHeaderCell: () => ({
                onClick: () => setSortArea(sortArea === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            }),
        },

        {
            title: "Priority ",
            dataIndex: "priority",
        },
        //{
        //    title: "Sub Priority ",
        //    dataIndex: "subPriority",
        //},
        //{
        //    title: "Header ",
        //    dataIndex: "header",
        //    className: "headerType",
        //    render: (headerType) => <Tooltip title={headerType}>{headerType}</Tooltip>,
        //},
        {
            title: "Media Type ",
            dataIndex: "mediaType",
        },

        {
            title: "Media Description ",
            dataIndex: "",
            className: "mediaDescription ellipsss",
            render: (data) => (<Tooltip placement='topLeft' className="media-tooltip"

                title={data.mediaKey == "Text" ?
                    <div>
                    <div dangerouslySetInnerHTML={{
                        __html: data.mediaDescription
                    }}>
                    </div> 
                    </div> : data.mediaDescription
                }>

                {data.mediaKey == "Text" ? <span className="preview_Link_desc">View Text</span> : data.mediaDescription}

                </Tooltip>)
        },

        {
            title: "Assign Thumbnail ",
            dataIndex: "thumbnailID",
            render: (thumbnailID, mediaType) => (
                <div className="thumbs">
                    {mediaType.qRcodeId == mediaType.primaryQR ?
                        mediaType.mediaType === "Image" &&

                        < input type="radio" className="btn-radio" name={mediaType.qRcodeId + '_radio'}
                            checked={thumbnailID != null} onChange={() => { assignThumbnail(mediaType.mediaId, mediaType.qRcodeId) }}
                        ></input> : ""
                    }
                    {mediaType.qRcodeId == mediaType.primaryQR ? thumbnailID != null && <img className="thumb img add" src={`${mediaType.filename}`} /> : ""}
                </div>)

        },
        {
            title: "Action ",
            dataIndex: "",
            render: (data, qRcodeId, mediaId) => (
                <>
                    {data.qRcodeId == data.primaryQR ? < button className="table-btn" >
                        <img src={Edit_light} onClick={() => handleEdit(data.qRcodeId, data.mediaId)}></img>
                    </button> : ""}
                    {data.qRcodeId == data.primaryQR ?
                        <button className="table-btn" >
                            <img src={Trash_light} onClick={() => handleDelete(data.mediaId)}></img>
                        </button> : ""}

                </>
            )
        },
    ]

    return (
        <Layout title="QR Review">
            <Toaster />
          
            <div className="position-relative reviewPage">
                <div className="card-box-header d-inline-flex w-100 align-items-center m-0 FlexWrap">
                    <div className="instructions index ForMob">
                        <span>
                            * Order Type : Guide, Priority
                        </span>
                        <span>
                            * Thumbnail apply on Images
                        </span>
                        
                    </div>

                    <div className="instructions tableins index ForMob">
                        <span className="selectmediaspan">
                            <div className="starmedia">
                                *
                            </div>

                            Select a table ID and Click + Add media to dublicate  the selected table's data into a new row
                        </span>
                    </div>

                    <div className="filter-section multi-filter d-flex flex-wrap p-0">
                        
                        <div className="col d-flex align-items-center p-0">
                            <label className="filter-col me-3 mb-0">Search Media:</label>
                            <div className="dropdown">
                                <button className="filter-btn dropdown-toggle d-flex align-items-center" type="button" id="dropdownFilterMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span>Filter</span> <img className='ms-2' src={Union} />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <div className="row g-3">
                                        <div className='col-md-12'>
                                            <div className='row justify-content-center align-items-center'>
                                                <div className='col-md-12 d-flex align-items-center searchbtQR'>
                                                    <label class="form-label mb-0 d-inline-block"><b>Enter QR Code :</b></label>
                                                    <input className="form-control" placeholder='Enter QR Code' type="text" maxLength="9"
                                                        value={qRcodeId == undefined ? "" : qRcodeId}
                                                        // ^(?:[1-9]\d*|0)$
                                                        onKeyPress={(e) => {
                                                            if (new RegExp(/^[0-9\b]+$/).test(e.key)) {
                                                            } else e.preventDefault();
                                                        }}
                                                        disabled={!currentCompanyId && !currentLocationId && !currentMediaId && !currentQRId ? "" : "disabled"}
                                                        onChange={(e) => {
                                                            setQRCodeId(e.target.value);
                                                            setCount(e.target.value.length);
                                                            if (e.target.value.startsWith('0') || count > 9) { setQRCodeError("Enter Valid QR Code") }
                                                            else { setQRCodeError("") }
                                                        }}
                                                    >
                                                    </input>
                                                    <strong>OR</strong>
                                                </div>
                                                {count > 8 ? <small className='text-danger d-block mt-2 ms-5 text-center' >Invalid Data</small> : ""}
                                                <small className='text-danger d-block mt-2 ms-5 text-center' >{qRcodeError}</small>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label class="form-label">Company</label>
                                            <select class="form-select mb-0  filter-select" aria-label=".form-select-lg example"
                                                value={currentCompanyId}
                                                disabled={!qRcodeId ? "" : "disabled"}
                                                onChange={(e) => handleCompanyChange(e)}>

                                                <option value="0">Select Company</option>
                                                {allCompanyDetails && allCompanyDetails.map(company =>
                                                    <option key={company.companyId} value={company.companyId}>
                                                        {company.companyName} </option>)}
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <label class="form-label">Location</label>

                                            <select class="form-select mb-0  filter-select" aria-label=".form-select-lg example"
                                                value={currentLocationId}
                                                disabled={!qRcodeId ? "" : "disabled"}
                                                onChange={(e) => { setCurrentLocationId(e.target.value) }}>
                                                <option value="0">Select Location</option>
                                                {
                                                    selectedCompanyLocations && selectedCompanyLocations.map(data => {
                                                        return <option key={data.location.locationId} value={data.location.locationId}>
                                                            {data.location.name}
                                                        </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <label class="form-label">Media</label>
                                            <select class="form-select mb-0  filter-select" aria-label=".form-select-lg example"
                                                value={currentMediaId}
                                                disabled={!qRcodeId ? "" : "disabled"}
                                                onChange={(e) => {
                                                    setCurrentMediaId(e.target.value)
                                                }}>
                                                <option value="0">Select Media</option>
                                                {
                                                    allMediaTypes && allMediaTypes.map(media =>
                                                        <option key={media.mediaTypeId} value={media.mediaTypeId}>
                                                            {media.name} </option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <label class="form-label">QR Code</label>
                                            <select class="form-select mb-0  filter-select" aria-label=".form-select-lg example"
                                                value={currentQRId}
                                                disabled={!qRcodeId ? "" : "disabled"}
                                                onChange={(e) => { setCurrentQRId(e.target.value) }}>
                                                <option value="0">Select Code</option>
                                                {
                                                    selectedQrByLocation && selectedQrByLocation.map(data => {
                                                        return <option key={data.qRcodeId} value={data.qRcodeId}>
                                                            {data.qRcodeId}
                                                        </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-12'>
                                            <button className="btn btn-primary pe-3 ps-3" onClick={() => {
                                                searchQrDetails(options)
                                                SaveSeachedItems(qRcodeId)

                                            }
                                            }>Search</button>
                                            <button className="btn btn-primary pe-3 ps-3 ms-2" onClick={
                                                () => {
                                                    setQRCodeId('');
                                                    resetButtonSearch();
                                                    setQRCodeError(false);
                                                    ClearSearchItem();
                                                }
                                            }>Reset</button>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>

                        <div className= "instructions index forDesk">
                            <span>
                                * Order Type : Guide, Priority
                            </span>
                            <span>
                                * Thumbnail apply on Images 
                            </span>                          
                        </div>

                        <div className="instructions tableins index forDesk">
                            <span className="selectmediaspan">
                                <div className="starmedia">
                                    *
                                </div>

                                Select a table ID and Click + Add media to dublicate  the selected table's data into a new row
                            </span>
                        </div>
                    </div>                   

                    <div className="ms-auto d-flex reviewbtn mobbtn">
                        {showButton ?
                            <button className="btn btn-primary d-flex align-items-center" onClick={() => handleDeleteMultiple()} {...checkedListOfIds.length >= 1}>
                                <img className="trash" src={Trash_light} ></img> Delete </button>
                            : ""}
                        {checkedListOfIds.length >= 1 && iswithmasterqr ? <Link className="btn btn-primary d-flex  ml-2"
                            to=
                            {{
                               // pathname: '/Add/QR/QrCodeId/' + qRcodeadd + '/MediaId/' + mediaadd,
                                state: {
                                    iswithmasterqr: iswithmasterqr,
                                }, }}
                            onClick={() => handleaddwithcheck()} ><img src={AddImage}></img>Add Media </Link>
                            : <Link className="btn btn-primary d-flex  ml-2" to="/Add/QR"  ><img src={AddImage}></img>Add Media </Link>}
                    </div>
                </div>
            </div>
            {loading ? <Loader /> :
                <>
                    <div className="Viewer-table position-relative mb-4 pagination-sticky ellipsss wk-select-arrow">
                        {loading ? <Loader /> : ''}
                        <Table

                            className='table-responsive antd--detail-table Viewer-table'
                            columns={columns}
                            dataSource={table}
                            pagination={options.pagination.total > 10 ? options.pagination : false}
                            onChange={handleChange}
                            paginationTotalRows={total}
                            scroll={{ x: "750px" }}
                        />
                    </div>
                </>
            }
        </Layout>
    )
}

export default connect()(IndexQrDetails);
