import React, { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';

const QRCodeGenerator = ({qrcodeid}) => {
   
    const { REACT_APP_SUPPORT } = process.env;
    const [data, setData] = useState('');

    useEffect(() => {
        // URL to open in the device on scanning
        const baseUrl = REACT_APP_SUPPORT;
       
        //binded props with base url
        const qrUrl = `${baseUrl}/${qrcodeid}`;            
        setData(qrUrl); // Set the data that will be used to generate the QR code
    }, [qrcodeid]); // Rerun the effect whenever any prop changes
    
    return (
        <>
        <div>          
            {data && typeof data === 'string' ? (
                <QRCodeSVG
                    value={data} // Ensure this is a valid string
                    size={80}
                        level="H"
                        fgColor="#b97a57"
                        bgColor="#FFFFFF" 
                />
            ) : (
                <div>Loading QR Code...</div>
            )}

           
            </div>
        </>
    );
};

export default QRCodeGenerator;

